import { defineComponent as _defineComponent } from 'vue'
import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, unref as _unref, createElementBlock as _createElementBlock } from "vue"

import router from '@/router';
import { statusSideBar } from '@/store/global';



export default /*@__PURE__*/_defineComponent({
  __name: 'LinkCustom',
  props: {
  icon: {
    type: Object,
    required: true,
  },
  link: {
    type: String,
    required: true,
  },
  text: {
    type: String,
    required: true,
  },
  isActive: {
    type: Boolean,
    required: true,
  },
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("a", {
    onClick: _cache[0] || (_cache[0] = ($event: any) => {
      _unref(router).push(__props.link);
      _unref(statusSideBar).visible = false;
    }),
    class: _normalizeClass(["ml-4 mr-4 pl-2 pr-2 pb-4 pt-4 flex gap-2 flex-1 items-center cursor-pointer", {
      'bg-white shadow-custom-shadow font-semibold text-[#344767]': __props.isActive,
    }])
  }, [
    _createElementVNode("i", {
      class: _normalizeClass(["w-8 h-8 p-[10px] flex items-center justify-center rounded-lg", {
        'shadow-none bg-custom-gradient-icon-active text-white': __props.isActive,
        'shadow-custom-shadow-icon bg-custom-gradient-icon text-[#344767]': !__props.isActive,
      }])
    }, [
      (_openBlock(), _createBlock(_resolveDynamicComponent(__props.icon), { class: "w-3 h-3" }))
    ], 2),
    _createTextVNode(" " + _toDisplayString(__props.text), 1)
  ], 2))
}
}

})