import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col gap-3 w-full" }
const _hoisted_2 = { class: "flex p-4 bg-custom-gradient-dark rounded-lg shadow-custom-shadow" }
const _hoisted_3 = { class: "flex flex-col gap-4 w-full" }
const _hoisted_4 = { class: "flex flex-wrap gap-3" }
const _hoisted_5 = { class: "flex flex-wrap gap-3 w-full justify-end" }

import PostCustom from '@/components/PostCustom.vue';
import { onMounted, ref, Ref, watch } from 'vue';
import { IEditUserDto, IUserDto } from '@/interfaces/dto';
import DeleteIcon from '@/components/icons/DeleteIcon.vue';
import EditIcon from '@/components/icons/EditIcon.vue';
import DeleteUser from '@/views/usersView/DeleteUser.vue';
import EditUser from './EditUser.vue';
import { UseLocaleTimeZone } from '@/helpers';


export default /*@__PURE__*/_defineComponent({
  __name: 'DetailsUser',
  props: {
    user: {}
  },
  emits: ['onDeleted', 'onEdit'],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const updateModel: Ref<IEditUserDto> = ref({
  id: props.user.id,
  username: props.user.username,
  email: props.user.email,
});
const emit = __emit;

const showDelete = ref(false);
const showEdit = ref(false);

onMounted(() => {
  window.location.href = `${window.location.pathname}#details`;
});

watch(
  () => props.user,
  (newValue) => {
    updateModel.value = { id: newValue.id, email: newValue.email, username: newValue.username };
  }
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[6] || (_cache[6] = _createElementVNode("h4", { class: "flex" }, "Detalles", -1)),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(PostCustom, {
              content: props.user.username,
              title: "Nombre"
            }, null, 8, ["content"]),
            _createVNode(PostCustom, {
              content: props.user.role,
              title: "Role"
            }, null, 8, ["content"]),
            _createVNode(PostCustom, {
              content: props.user.email,
              title: "Correo"
            }, null, 8, ["content"]),
            _createVNode(PostCustom, {
              content: _unref(UseLocaleTimeZone).longDate(props.user.createAt),
              title: "Creado en"
            }, null, 8, ["content"])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("i", {
              class: "p-2 flex items-center justify-center rounded-lg hover:bg-yellow-400 text-white h-min transition-all cursor-pointer hover:text-black",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (showEdit.value = true))
            }, [
              _createVNode(EditIcon, { class: "w-6 h-6" })
            ]),
            _createElementVNode("i", {
              class: "p-2 flex items-center justify-center rounded-lg hover:bg-red-600 text-white h-min transition-all cursor-pointer hover:text-black",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (showDelete.value = true))
            }, [
              _createVNode(DeleteIcon, { class: "w-6 h-6" })
            ])
          ])
        ])
      ])
    ]),
    (showDelete.value)
      ? (_openBlock(), _createBlock(DeleteUser, {
          key: 0,
          "user-id": props.user.id,
          onClose: _cache[2] || (_cache[2] = ($event: any) => (showDelete.value = false)),
          onOnDeleted: _cache[3] || (_cache[3] = ($event: any) => (emit('onDeleted'), (showDelete.value = false)))
        }, null, 8, ["user-id"]))
      : _createCommentVNode("", true),
    (showEdit.value)
      ? (_openBlock(), _createBlock(EditUser, {
          key: 1,
          data: updateModel.value,
          onClose: _cache[4] || (_cache[4] = ($event: any) => (showEdit.value = false)),
          onUserEdit: _cache[5] || (_cache[5] = ($event: any) => (emit('onEdit'), (showEdit.value = false)))
        }, null, 8, ["data"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})