import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"
import _imports_0 from '../../public/logo.png'


const _hoisted_1 = { class: "w-[250px] h-[100vh] pt-4 pb-4 pl-4 flex-col flex justify-between bg-[#f8f9fa]" }
const _hoisted_2 = { class: "flex flex-col" }
const _hoisted_3 = { class: "flex flex-col" }
const _hoisted_4 = { class: "flex flex-col gap-3 text-end flex-[0 1 0%] mr-4" }
const _hoisted_5 = { class: "font-bold text-xl" }

import { markRaw, ref } from 'vue';
import LinkCustom from '@/components/LinkCustom.vue';
/* import DashBoardIcon from '@/components/icons/DashBoardIcon.vue';
import ProfileIcon from '@/components/icons/ProfileIcon.vue';
import SettingIcon from '@/components/icons/SettingIcon.vue'; */
import UsersIcon from '@/components/icons/UsersIcon.vue';
import BankIcon from '@/components/icons/BankIcon.vue';
import RegisterIcon from '@/components/icons/RegisterIcon.vue';
import router from '@/router';
import { roleAndUserGlobal, permissions, hasPermission } from '@/store/RolesAndPermission';
import { statusSideBar } from '@/store/global';


export default /*@__PURE__*/_defineComponent({
  __name: 'SideBarView',
  setup(__props) {

const links = ref([
  /*   { icon: markRaw(DashBoardIcon), text: 'Dashboard', link: 'dashboard', permission: permissions.viewDashboard }, */
  { icon: markRaw(BankIcon), text: 'Fondos', link: 'funds', permission: permissions.viewFund },
  { icon: markRaw(RegisterIcon), text: 'Registro', link: 'register', permission: permissions.viewRegister },
  { icon: markRaw(UsersIcon), text: 'Usuarios', link: 'users', permission: permissions.viewUser },
  /*   { icon: markRaw(SettingIcon), text: 'Configuraciones', link: 'settings', permission: permissions.viewSettings },
  { icon: markRaw(ProfileIcon), text: 'Opciones de usuario', link: 'profile', permission: permissions.viewAll }, */
]);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "pl-8 pr-8 pt-6 pb-6" }, [
        _createElementVNode("img", {
          src: _imports_0,
          alt: "logo"
        })
      ], -1)),
      _cache[2] || (_cache[2] = _createElementVNode("hr", { class: "border-none h-[1px] flex w-full mb-4 bg-custom-gradient-linear" }, null, -1)),
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(links.value, (item, index) => {
          return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
            (_unref(hasPermission)(item.permission))
              ? (_openBlock(), _createBlock(LinkCustom, {
                  key: 0,
                  icon: item.icon,
                  text: item.text,
                  "is-active": _unref(router).currentRoute.value.name === item.link,
                  link: item.link
                }, null, 8, ["icon", "text", "is-active", "link"]))
              : _createCommentVNode("", true)
          ], 64))
        }), 128))
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("p", _hoisted_5, _toDisplayString(_unref(roleAndUserGlobal).name), 1),
      _createElementVNode("button", {
        type: "submit",
        class: "bg-primary text-white",
        onClick: _cache[0] || (_cache[0] = ($event: any) => {
          _unref(router).push('/');
          _unref(statusSideBar).visible = false;
        })
      }, " CERRAR SESIÓN ")
    ])
  ]))
}
}

})