import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex w-xl flex-col gap-4 p-4 bg-custom-gradient-dark rounded-lg shadow-custom-shadow" }
const _hoisted_2 = { class: "flex flex-wrap gap-3" }

import PostCustom from '@/components/PostCustom.vue';
import { Ref, ref, watch } from 'vue';
import { IFundLogDto } from '@/interfaces/dto';
import { UseLocaleTimeZone } from '@/helpers';


export default /*@__PURE__*/_defineComponent({
  __name: 'FundLogDetails',
  props: {
    log: {}
  },
  setup(__props: any) {

const props = __props;
const data: Ref<IFundLogDto> = ref(props.log);

// Watch for changes in the id prop
watch(
  () => props.log,
  () => {
    data.value = props.log;
    window.location.href = `${window.location.pathname}#details`;
  }
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[0] || (_cache[0] = _createElementVNode("h4", null, "Detalles", -1)),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(PostCustom, {
          content: data.value.user,
          title: "Usuario"
        }, null, 8, ["content"]),
        _createVNode(PostCustom, {
          content: data.value.fund,
          title: "Fondo"
        }, null, 8, ["content"]),
        _createVNode(PostCustom, {
          content: data.value.activity,
          title: "Accion"
        }, null, 8, ["content"]),
        (data.value.transactionType)
          ? (_openBlock(), _createBlock(PostCustom, {
              key: 0,
              content: data.value.transactionType,
              title: "Transaccion"
            }, null, 8, ["content"]))
          : _createCommentVNode("", true),
        (data.value.currency)
          ? (_openBlock(), _createBlock(PostCustom, {
              key: 1,
              content: data.value.currency,
              title: "Moneda"
            }, null, 8, ["content"]))
          : _createCommentVNode("", true),
        (data.value.amount)
          ? (_openBlock(), _createBlock(PostCustom, {
              key: 2,
              content: data.value.amount,
              title: "Monto"
            }, null, 8, ["content"]))
          : _createCommentVNode("", true),
        _createVNode(PostCustom, {
          content: _unref(UseLocaleTimeZone).longDate(data.value.createdAt),
          title: "Fecha"
        }, null, 8, ["content"]),
        _createVNode(PostCustom, {
          content: data.value.details || 'No disponible',
          title: "Detalles"
        }, null, 8, ["content"])
      ])
    ])
  ], 64))
}
}

})