import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelDynamic as _vModelDynamic, createElementVNode as _createElementVNode, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "flex flex-col" }
const _hoisted_2 = {
  key: 0,
  class: "ml-1 mb-2"
}
const _hoisted_3 = ["type", "title", "placeholder"]
const _hoisted_4 = {
  key: 1,
  class: "text-sm text-red-600"
}

import { ref, watch } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'InputCustom',
  props: {
  title: {
    type: String,
  },
  type: {
    type: String,
    required: true,
  },
  placeholder: {
    type: String,
  },
  modelValue: {
    type: [String, Number], // Permitir tanto String como Number
  },
  showError: {
    type: Boolean,
    default: false,
  },
  ErrorText: {
    type: String,
    default: 'Este campo es requerido',
  },
},
  emits: ['update:modelValue'],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;
const internalValue = ref(props.modelValue);

watch(
  () => props.modelValue,
  (newValue) => {
    internalValue.value = newValue;
  }
);

watch(internalValue, (newValue) => {
  emit('update:modelValue', newValue);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (__props.title)
      ? (_openBlock(), _createElementBlock("label", _hoisted_2, _toDisplayString(__props.title), 1))
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("input", {
      class: "w-full",
      type: __props.type,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((internalValue).value = $event)),
      title: __props.title,
      placeholder: __props.placeholder
    }, null, 8, _hoisted_3), [
      [_vModelDynamic, internalValue.value]
    ]),
    (__props.showError)
      ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(__props.ErrorText), 1))
      : _createCommentVNode("", true)
  ]))
}
}

})