import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col gap-6" }
const _hoisted_2 = {
  id: "details",
  class: "flex flex-wrap gap-6"
}

import ListModal from '../default/ListModal.vue';
import { IUserDto } from '@/interfaces/dto';
import { onMounted, ref } from 'vue';
import { userService } from '@/services/userService';
import AddUser from './AddUser.vue';
import DetailsUser from './DetailsUser.vue';
import { UseLocaleTimeZone } from '@/helpers';


export default /*@__PURE__*/_defineComponent({
  __name: 'UsersView',
  setup(__props) {

const showAdd = ref(false);
const showFilter = ref(false);
const data = ref<IUserDto[]>([]);
const header = ref<string[]>(['Usuario', 'Correo', 'Rol', 'Fecha']);
const keys = ref<string[]>(['username', 'email', 'role', 'createAt']);
const loading = ref(false);
const pageCurrent = ref(1);
const totalPageCurrent = ref(0);
const enabledNext = ref(false);
const enabledBack = ref(false);
const userSelected = ref<IUserDto | null>(null);

const showAddFunction = () => {
  showAdd.value = true;
};
const handleUserAdded = async () => {
  showAdd.value = false;
  await fetchData();
};
const showFilterFunction = () => {
  showFilter.value = true;
};
const fetchData = async () => {
  loading.value = true;
  data.value = [];
  const res = await userService.list(undefined, pageCurrent.value - 1, 10, true);
  if (res === undefined) return;
  totalPageCurrent.value = Math.ceil(res.totalLenght / 10);
  data.value = res.data;
  enabledNext.value = pageCurrent.value < totalPageCurrent.value;
  enabledBack.value = pageCurrent.value > 1;
  loading.value = false;
  userSelected.value !== null && getUserFromId(userSelected.value as { id: string });
};
const formatFundDataIntoTableInput = (data: IUserDto) => {
  return {
    id: data.id,
    username: data.username,
    email: data.email,
    role: data.role,
    createAt: UseLocaleTimeZone.shortDate(data.createAt),
  };
};
const nextPage = () => {
  if (pageCurrent.value < totalPageCurrent.value) {
    pageCurrent.value++;
    fetchData();
  }
};
const backPage = () => {
  if (pageCurrent.value > 1) {
    pageCurrent.value--;
    fetchData();
  }
};
const startPage = () => {
  pageCurrent.value = 1;
  fetchData();
};

const endPage = () => {
  pageCurrent.value = totalPageCurrent.value;
  fetchData();
};
const getUserFromId = (user: { id: string }) =>
  (userSelected.value = data.value.find((u: IUserDto) => u.id == user.id) || null);

onMounted(async () => {
  await fetchData();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(ListModal, {
        name: "Usuarios",
        "add-enabled": true,
        data: data.value.map(formatFundDataIntoTableInput),
        "is-loading": loading.value,
        header: header.value,
        keys: keys.value,
        "show-add": showAddFunction,
        "next-page": nextPage,
        "enabled-next": enabledNext.value,
        "enabled-back": enabledBack.value,
        "back-page": backPage,
        "page-current": pageCurrent.value,
        "button-label": "AGREGAR USUARIO",
        "total-page": totalPageCurrent.value,
        "start-page": startPage,
        "end-page": endPage,
        "show-filter": showFilterFunction,
        "filter-enabled": false,
        onReturnItem: getUserFromId
      }, null, 8, ["data", "is-loading", "header", "keys", "enabled-next", "enabled-back", "page-current", "total-page"]),
      _createElementVNode("section", _hoisted_2, [
        (userSelected.value !== null)
          ? (_openBlock(), _createBlock(DetailsUser, {
              key: 0,
              user: userSelected.value,
              onOnDeleted: _cache[0] || (_cache[0] = ($event: any) => (fetchData(), (userSelected.value = null))),
              onOnEdit: _cache[1] || (_cache[1] = ($event: any) => (fetchData()))
            }, null, 8, ["user"]))
          : _createCommentVNode("", true)
      ])
    ]),
    (showAdd.value)
      ? (_openBlock(), _createBlock(AddUser, {
          key: 0,
          onClose: _cache[2] || (_cache[2] = ($event: any) => (showAdd.value = false)),
          onUserAdded: handleUserAdded
        }))
      : _createCommentVNode("", true)
  ], 64))
}
}

})