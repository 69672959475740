import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "fixed flex h-[100vh] w-[100vw] items-center justify-center p-4 max-sm:p-0 max-sm:rounded-none z-50 top-0 left-0 backdrop-blur-[3px] bg-[rgba(0,0,0,0.4)]"
}
const _hoisted_2 = { class: "flex flex-col gap-4" }
const _hoisted_3 = { class: "flex flex-col gap-4" }
const _hoisted_4 = { class: "flex max-sm:flex-col gap-4" }
const _hoisted_5 = { class: "flex max-sm:flex-col gap-4" }
const _hoisted_6 = { class: "flex flex-col" }
const _hoisted_7 = { class: "flex gap-4" }
const _hoisted_8 = { class: "flex flex-col" }
const _hoisted_9 = { class: "flex max-sm:flex-col gap-4" }
const _hoisted_10 = { class: "flex flex-col gap-2" }
const _hoisted_11 = { class: "flex gap-3" }

import { onMounted, Ref, ref } from 'vue';
import InputSelect from '@/components/InputSelect.vue';
import InputMultiSelect from '@/components/InputMultiSelect.vue';
import { currencyService, fundService } from '@/services';
import { ActionType, IFundLogsFilter, TransactionType } from '@/interfaces/dto';
import CustomCheckBox from '@/components/CustomCheckBox.vue';
import { userService } from '@/services/userService';

interface option {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
  text: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'FundLogsFilters',
  props: {
    closeFilter: { type: Function },
    showFilter: { type: Boolean }
  },
  emits: ['filterValue'],
  setup(__props: any, { emit: __emit }) {


const emit = __emit;

const filter: Ref<IFundLogsFilter> = ref({ desc: true });
const showErrorGeneral: Ref<boolean> = ref(false);
const errorText: Ref<string> = ref('Hubo un error obteniendo los datos');
const optionSelectFundName: Ref<option[]> = ref([]);
const optionsFundsNames: Ref<option[]> = ref([]);
const optionSelectUsername: Ref<option[]> = ref([]);
const optionsUsernames: Ref<option[]> = ref([]);
const optionSelectCurrency: Ref<option[]> = ref([]);
const optionsCurrencies: Ref<option[]> = ref([]);
const optionSelectOrderBy: Ref<option> = ref({ value: false, text: 'Fecha' });
const optionsOrderBy: Ref<option[]> = ref([
  { value: true, text: 'Monto' },
  { value: false, text: 'Fecha' },
]);
const optionSelectTransaction: Ref<option[]> = ref([]);
const optionsTransactions: Ref<option[]> = ref([
  { value: ActionType.Deposit, text: ActionType.Deposit },
  { value: ActionType.Withdrawal, text: ActionType.Withdrawal },
]);
const optionSelectAction: Ref<option[]> = ref([]);
const optionsActions: Ref<option[]> = ref([
  { value: TransactionType.CreateFund, text: TransactionType.CreateFund },
  { value: TransactionType.DeleteCurrency, text: TransactionType.DeleteCurrency },
  { value: TransactionType.DeleteFund, text: TransactionType.DeleteFund },
  { value: TransactionType.Deposit, text: TransactionType.Deposit },
  { value: TransactionType.Transfer, text: TransactionType.Transfer },
  { value: TransactionType.Withdrawal, text: TransactionType.Withdrawal },
]);

onMounted(() => {
  fetchCurrencies();
  fetchFundsNames();
  fetchUsernames();
});

const fetchFundsNames = async () => {
  try {
    const res = await fundService.list({ fundNames: filter.value.funds }, 0, 10);
    optionsFundsNames.value = res.data.map((fund) => ({
      value: fund.name,
      text: fund.name,
    }));
  } catch (error) {
    showErrorGeneral.value = true;
    console.error(error);
  }
};

const fetchUsernames = async () => {
  try {
    const res = await userService.list(filter.value.users);
    optionsUsernames.value = res.data.map((user) => ({
      value: user.username,
      text: user.username,
    }));
  } catch (error) {
    showErrorGeneral.value = true;
    console.error(error);
  }
};

const fetchCurrencies = async () => {
  try {
    const res = await currencyService.list();
    optionsCurrencies.value = res.map((currency) => ({
      value: currency.id,
      text: currency.currency,
    }));
  } catch (error) {
    showErrorGeneral.value = true;
    console.error(error);
  }
};

const resetFilters = () => {
  filter.value = { desc: true };
  optionSelectFundName.value = [];
  optionSelectUsername.value = [];
  optionSelectCurrency.value = [];
  optionSelectOrderBy.value = { value: false, text: 'Fecha' };

  showErrorGeneral.value = false;
  emit('filterValue', { desc: true });
};

return (_ctx: any,_cache: any) => {
  return (_ctx.showFilter)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("form", {
          autocomplete: "off",
          class: "flex justify-center max-sm:h-[100vh] w-[600px] max-sm:w-full flex-col gap-6 bg-white p-12 max-sm:p-6 shadow-custom-shadow rounded-lg",
          onSubmit: _cache[13] || (_cache[13] = _withModifiers(($event: any) => (emit('filterValue', filter.value)), ["prevent"]))
        }, [
          _cache[17] || (_cache[17] = _createElementVNode("div", { class: "flex flex-col gap-2" }, [
            _createElementVNode("h4", { class: "text-primary" }, "Filtrar registros de fondos")
          ], -1)),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createVNode(InputMultiSelect, {
                  "model-value": optionSelectFundName.value,
                  options: optionsFundsNames.value,
                  "show-search": true,
                  placeholder: "---",
                  title: "Fondos",
                  onEmitValues: _cache[0] || (_cache[0] = async (values) => ((filter.value.funds = values), await fetchFundsNames()))
                }, null, 8, ["model-value", "options"]),
                _createVNode(InputMultiSelect, {
                  "model-value": optionSelectUsername.value,
                  options: optionsUsernames.value,
                  "show-search": true,
                  placeholder: "---",
                  title: "Usuarios",
                  onEmitValues: _cache[1] || (_cache[1] = async (values) => ((filter.value.users = values), await fetchUsernames()))
                }, null, 8, ["model-value", "options"])
              ]),
              _createElementVNode("div", _hoisted_5, [
                _createVNode(InputMultiSelect, {
                  "model-value": optionSelectCurrency.value,
                  options: optionsCurrencies.value,
                  placeholder: "---",
                  title: "Monedas",
                  onEmitValues: _cache[2] || (_cache[2] = (values) => (filter.value.currencies = values))
                }, null, 8, ["model-value", "options"]),
                _createVNode(InputMultiSelect, {
                  "model-value": optionSelectTransaction.value,
                  options: optionsTransactions.value,
                  placeholder: "---",
                  title: "Tipo de transacción",
                  onEmitValues: _cache[3] || (_cache[3] = (values) => (filter.value.fundTransactions = values))
                }, null, 8, ["model-value", "options"])
              ]),
              _createVNode(InputMultiSelect, {
                "model-value": optionSelectAction.value,
                options: optionsActions.value,
                placeholder: "---",
                title: "Acción",
                onEmitValues: _cache[4] || (_cache[4] = (values) => (filter.value.activities = values))
              }, null, 8, ["model-value", "options"]),
              _createElementVNode("div", _hoisted_6, [
                _cache[14] || (_cache[14] = _createElementVNode("label", { class: "mb-2" }, "Rango del Monto", -1)),
                _createElementVNode("div", _hoisted_7, [
                  _withDirectives(_createElementVNode("input", {
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((filter.value.amountMin) = $event)),
                    placeholder: "Mínimo",
                    type: "number"
                  }, null, 512), [
                    [_vModelText, filter.value.amountMin]
                  ]),
                  _withDirectives(_createElementVNode("input", {
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((filter.value.amountMax) = $event)),
                    placeholder: "Máximo",
                    type: "number"
                  }, null, 512), [
                    [_vModelText, filter.value.amountMax]
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_8, [
                _cache[15] || (_cache[15] = _createElementVNode("label", { class: "mb-2" }, "Rango de la Fecha: Desde - Hasta", -1)),
                _createElementVNode("div", _hoisted_9, [
                  _withDirectives(_createElementVNode("input", {
                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((filter.value.since) = $event)),
                    class: "w-[calc(50%-.5rem)] max-sm:w-full",
                    type: "datetime-local"
                  }, null, 512), [
                    [_vModelText, filter.value.since]
                  ]),
                  _withDirectives(_createElementVNode("input", {
                    "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((filter.value.until) = $event)),
                    class: "w-[calc(50%-.5rem)] max-sm:w-full",
                    type: "datetime-local"
                  }, null, 512), [
                    [_vModelText, filter.value.until]
                  ])
                ])
              ]),
              _createVNode(InputSelect, {
                "model-value": optionSelectOrderBy.value,
                options: optionsOrderBy.value,
                title: "Ordenar por",
                onEmitValue: _cache[9] || (_cache[9] = (value) => (filter.value.amountOrCreateDate = value))
              }, null, 8, ["model-value", "options"]),
              _createVNode(CustomCheckBox, {
                title: "Ordenar descendiente:",
                "default-value": filter.value.desc,
                onReturnValue: _cache[10] || (_cache[10] = (value) => filter.value.desc = value)
              }, null, 8, ["default-value"])
            ]),
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("span", {
                class: _normalizeClass([{
              'scale-100': showErrorGeneral.value,
              'scale-0': !showErrorGeneral.value,
            }, "text-sm text-red-600 transition-all"])
              }, _toDisplayString(errorText.value), 3),
              _cache[16] || (_cache[16] = _createElementVNode("button", {
                class: "w-full bg-primary text-white",
                type: "submit"
              }, "FILTRAR", -1)),
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("button", {
                  class: "w-full bg-[#717ef5] text-white flex-1",
                  type: "button",
                  onClick: _cache[11] || (_cache[11] = ($event: any) => (resetFilters()))
                }, " RESTABLECER "),
                _createElementVNode("button", {
                  class: "w-full bg-white text-primary border-primary border-solid border-[1px] text-nowrap flex-1",
                  type: "button",
                  onClick: _cache[12] || (_cache[12] = 
//@ts-ignore
(...args) => (_ctx.closeFilter && _ctx.closeFilter(...args)))
                }, " CERRAR ")
              ])
            ])
          ])
        ], 32)
      ]))
    : _createCommentVNode("", true)
}
}

})