import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, vShow as _vShow } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "ml-1 mb-2"
}
const _hoisted_2 = { class: "relative" }
const _hoisted_3 = { class: "absolute z-10 mt-1 w-full bg-white shadow-custom-shadow max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = {
  key: 0,
  class: "absolute inset-y-0 right-0 flex items-center pr-4 text-blue-400"
}
const _hoisted_6 = {
  key: 1,
  class: "text-sm text-red-600"
}

import { ref, watch, onMounted, onBeforeUnmount, computed } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'InputMultiSelect',
  props: {
  title: String,
  options: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    type: Array as () => { value: any; text: string }[],
    required: true,
  },
  modelValue: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    type: Array as () => { value: any; text: string }[],
    default: () => [],
  },
  showError: {
    type: Boolean,
    default: false,
  },
  ErrorText: {
    type: String,
    default: 'Este campo es requerido',
  },
  placeholder: {
    type: String,
    default: 'Seleccione una opción',
  },
  showSearch: {
    type: Boolean,
    default: false,
  },
},
  emits: ['update:modelValue', 'emitValues'],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;
const internalValue = ref(props.modelValue);
const isOpen = ref(false);
const selectedOptionsText = ref<string[]>([]);
const dropdown = ref<HTMLElement | null>(null);
const searchQuery = ref('');

const checkDefaultValue = () => {
  selectedOptionsText.value = props.options
    .filter((option) => internalValue.value.some((val) => val.value === option.value))
    .map((option) => option.text);
};

const handleClickOutside = (event: MouseEvent) => {
  if (dropdown.value && !dropdown.value.contains(event.target as Node) && isOpen.value) {
    emit(
      'emitValues',
      internalValue.value.map((values) => values.value)
    ); // Emitir solo los valores seleccionados
    isOpen.value = false;
    searchQuery.value = '';
  }
};

onMounted(() => {
  checkDefaultValue();
  document.addEventListener('click', handleClickOutside);
});

onBeforeUnmount(() => {
  document.removeEventListener('click', handleClickOutside);
});

watch(internalValue, (newValue) => {
  emit('update:modelValue', newValue);
});
watch(
  () => props.modelValue,
  (newValue) => {
    internalValue.value = newValue;
    selectedOptionsText.value = props.options
      .filter((option) => internalValue.value.some((val) => val.value === option.value))
      .map((option) => option.text);
  },
  { deep: true }
);

const toggleDropdown = () => {
  isOpen.value = !isOpen.value;
  if (!isOpen.value) {
    searchQuery.value = '';
  }
  emit('emitValues', [...internalValue.value.map((values) => values.value)]);
};

const emitSearch = () => {
  emit('emitValues', [...internalValue.value.map((values) => values.value), searchQuery.value]);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const toggleOption = (option: { value: any; text: string }) => {
  if (internalValue.value.some((val) => val.value === option.value)) {
    internalValue.value = internalValue.value.filter((val) => val.value !== option.value);
  } else {
    internalValue.value.push(option);
  }
  selectedOptionsText.value = props.options
    .filter((option) => internalValue.value.some((val) => val.value === option.value))
    .map((option) => option.text);
  emit('emitValues', [...internalValue.value.map((values) => values.value), searchQuery.value]);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isSelected = (option: { value: any }) => {
  return internalValue.value.some((val) => val.value === option.value);
};

const filteredOptions = computed(() => {
  if (!searchQuery.value) {
    return props.options;
  }
  const selectedOptions = props.options.filter((option) =>
    internalValue.value.some((val) => val.value === option.value)
  );
  const searchOptions = props.options.filter((option) =>
    option.text.toLowerCase().includes(searchQuery.value.toLowerCase())
  );
  return [...new Set([...selectedOptions, ...searchOptions])];
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "flex grow flex-col relative",
    ref_key: "dropdown",
    ref: dropdown
  }, [
    (__props.title)
      ? (_openBlock(), _createElementBlock("label", _hoisted_1, _toDisplayString(__props.title), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", {
        type: "button",
        onClick: toggleDropdown,
        class: "text text-start selectInput"
      }, [
        _createTextVNode(_toDisplayString(selectedOptionsText.value.length ? selectedOptionsText.value.join(', ') : __props.placeholder) + " ", 1),
        _cache[1] || (_cache[1] = _createElementVNode("span", { class: "absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none" }, [
          _createElementVNode("svg", {
            class: "h-5 w-5 text-gray-400",
            viewBox: "0 0 20 20",
            fill: "currentColor"
          }, [
            _createElementVNode("path", {
              "fill-rule": "evenodd",
              d: "M10 3a1 1 0 01.707.293l5 5a1 1 0 01-1.414 1.414L10 5.414 5.707 9.707A1 1 0 014.293 8.293l5-5A1 1 0 0110 3z",
              "clip-rule": "evenodd"
            })
          ])
        ], -1))
      ]),
      _withDirectives(_createElementVNode("div", _hoisted_3, [
        (__props.showSearch)
          ? _withDirectives((_openBlock(), _createElementBlock("input", {
              key: 0,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((searchQuery).value = $event)),
              onInput: emitSearch,
              type: "text",
              placeholder: "Buscar...",
              class: "w-full border-none focus:border-none focus:shadow-none"
            }, null, 544)), [
              [_vModelText, searchQuery.value]
            ])
          : _createCommentVNode("", true),
        _createElementVNode("ul", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filteredOptions.value, (option) => {
            return (_openBlock(), _createElementBlock("li", {
              key: option.value,
              onClick: ($event: any) => (toggleOption(option)),
              class: "cursor-pointer select-none relative py-2 pl-3 pr-9"
            }, [
              _createElementVNode("span", {
                class: _normalizeClass([{
                'font-semibold': isSelected(option),
                'font-normal': !isSelected(option),
              }, "block truncate"])
              }, _toDisplayString(option.text), 3),
              (isSelected(option))
                ? (_openBlock(), _createElementBlock("span", _hoisted_5, _cache[2] || (_cache[2] = [
                    _createElementVNode("svg", {
                      class: "h-5 w-5",
                      viewBox: "0 0 20 20",
                      fill: "currentColor"
                    }, [
                      _createElementVNode("path", {
                        "fill-rule": "evenodd",
                        d: "M16.707 5.293a1 1 0 00-1.414 0L9 11.586 6.707 9.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l7-7a1 1 0 000-1.414z",
                        "clip-rule": "evenodd"
                      })
                    ], -1)
                  ])))
                : _createCommentVNode("", true)
            ], 8, _hoisted_4))
          }), 128))
        ])
      ], 512), [
        [_vShow, isOpen.value]
      ])
    ]),
    (__props.showError)
      ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(__props.ErrorText), 1))
      : _createCommentVNode("", true)
  ], 512))
}
}

})