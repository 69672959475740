import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "fixed flex h-[100vh] w-[100vw] items-center justify-center p-4 z-50 top-0 left-0 backdrop-blur-[3px] bg-[rgba(0,0,0,0.4)]" }
const _hoisted_2 = { class: "flex flex-col gap-4" }
const _hoisted_3 = { class: "flex flex-col gap-4" }
const _hoisted_4 = { class: "flex flex-col gap-2" }

import { onMounted, ref } from 'vue';
import InputCustom from '@/components/InputCustom.vue';
import InputSelect from '@/components/InputSelect.vue';
import { IRegisterUserDto } from '@/interfaces/dto';
import { authService, roleService } from '@/services';
import { ICustomSelectOption } from '@/interfaces';


export default /*@__PURE__*/_defineComponent({
  __name: 'AddUser',
  emits: ['userAdded', 'close'],
  setup(__props, { emit: __emit }) {

const emit = __emit;

const model = ref<IRegisterUserDto>({ roleId: '', username: '', email: '', password: '', passwordConfirmation: '' });
const errorText = ref('Hubo un error creando el usuario');
const selectRole = ref<ICustomSelectOption<string>>();
const optionsRoles = ref<ICustomSelectOption<string>[]>([]);
const showError = ref({
  general: false,
  username: false,
  roleId: false,
  email: false,
  password: false,
  passwordConfirmation: false,
});
const errorMessages = ref({
  username: '',
  roleId: '',
  email: '',
  password: '',
  passwordConfirmation: '',
});

const handleAdd = async () => {
  showError.value.general = false;
  if (validationUserCreate()) {
    try {
      await authService.register(model.value);
      emit('userAdded');
    } catch (error) {
      showError.value.general = true;
      console.error('Create failed:', error);
    }
  }
};
const fetchRole = async () => {
  const res = await roleService.list().catch((error) => {
    showError.value.general = true;
    console.error('Error al obtener los roles:', error);
    throw error;
  });
  optionsRoles.value = res.map((role) => ({ value: role.id, text: role.role }));
};
const validationUserCreate = () => {
  showError.value = {
    general: false,
    username: false,
    roleId: false,
    email: false,
    password: false,
    passwordConfirmation: false,
  };

  if (!model.value.username) {
    errorMessages.value.username = 'Este campo es requerido';
    showError.value.username = true;
  } else if (model.value.username.length < 2) {
    errorMessages.value.username = 'El nombre debe tener más de dos caracteres';
    showError.value.username = true;
  } else {
    showError.value.username = false;
  }

  if (!model.value.email) {
    errorMessages.value.email = 'Este campo es requerido';
    showError.value.email = true;
  } else if (!validateEmail(model.value.email)) {
    errorMessages.value.email = 'El correo debe ser válido';
    showError.value.email = true;
  } else {
    showError.value.email = false;
  }

  if (!model.value.password) {
    errorMessages.value.password = 'Este campo es requerido';
    showError.value.password = true;
  } else if (model.value.password.length < 8) {
    errorMessages.value.password = 'La contraseña debe tener más de 8 caracteres';
    showError.value.password = true;
  } else {
    showError.value.password = false;
  }

  if (!model.value.passwordConfirmation) {
    errorMessages.value.passwordConfirmation = 'Este campo es requerido';
    showError.value.passwordConfirmation = true;
  } else if (model.value.password !== model.value.passwordConfirmation) {
    errorMessages.value.passwordConfirmation = 'Las contraseñas deben coincidir';
    showError.value.passwordConfirmation = true;
  } else {
    showError.value.passwordConfirmation = false;
  }

  if (!model.value.roleId) {
    errorMessages.value.roleId = 'Este campo es requerido';
    showError.value.roleId = true;
  } else {
    showError.value.roleId = false;
  }

  return !Object.values(showError.value).includes(true);
  function validateEmail(email: string) {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  }
};

onMounted(fetchRole);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("form", {
      onSubmit: _withModifiers(handleAdd, ["prevent"]),
      class: "flex w-[500px] flex-col gap-6 bg-white p-12 shadow-custom-shadow rounded-lg",
      autocomplete: "off"
    }, [
      _cache[7] || (_cache[7] = _createElementVNode("div", { class: "flex flex-col gap-2" }, [
        _createElementVNode("h4", { class: "text-primary" }, "Agregar Usuario")
      ], -1)),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(InputCustom, {
            modelValue: model.value.username,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((model.value.username) = $event)),
            "show-error": showError.value.username,
            "-error-text": errorMessages.value.username,
            placeholder: "---",
            title: "Nombre",
            type: "text"
          }, null, 8, ["modelValue", "show-error", "-error-text"]),
          _createVNode(InputCustom, {
            modelValue: model.value.email,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((model.value.email) = $event)),
            "show-error": showError.value.email,
            "-error-text": errorMessages.value.email,
            placeholder: "---",
            title: "Correo",
            type: "text"
          }, null, 8, ["modelValue", "show-error", "-error-text"]),
          _createVNode(InputSelect, {
            "model-value": selectRole.value,
            options: optionsRoles.value,
            "-error-text": errorMessages.value.roleId,
            "show-error": showError.value.roleId,
            placeholder: "---",
            title: "Rol",
            "onUpdate:modelValue": _cache[2] || (_cache[2] = (value) => (model.value.roleId = value.value))
          }, null, 8, ["model-value", "options", "-error-text", "show-error"]),
          _createVNode(InputCustom, {
            modelValue: model.value.password,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((model.value.password) = $event)),
            "show-error": showError.value.password,
            "-error-text": errorMessages.value.password,
            placeholder: "---",
            title: "Contraseña",
            type: "password"
          }, null, 8, ["modelValue", "show-error", "-error-text"]),
          _createVNode(InputCustom, {
            modelValue: model.value.passwordConfirmation,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((model.value.passwordConfirmation) = $event)),
            "show-error": showError.value.passwordConfirmation,
            "-error-text": errorMessages.value.passwordConfirmation,
            placeholder: "---",
            title: "Confirmar Contraseña",
            type: "password"
          }, null, 8, ["modelValue", "show-error", "-error-text"])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("span", {
            class: _normalizeClass(["text-sm text-red-600 transition-all", {
              'scale-100': showError.value.general,
              'scale-0': !showError.value.general,
            }])
          }, _toDisplayString(errorText.value), 3),
          _cache[6] || (_cache[6] = _createElementVNode("button", {
            class: "w-full bg-primary text-white",
            type: "submit"
          }, "AGREGAR", -1)),
          _createElementVNode("button", {
            onClick: _cache[5] || (_cache[5] = ($event: any) => (emit('close'))),
            type: "button",
            class: "w-full bg-white text-primary border-primary border-solid border-[1px] text-nowrap"
          }, " CERRAR ")
        ])
      ])
    ], 32)
  ]))
}
}

})