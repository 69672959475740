import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "min-[480px]:p-6 p-4 flex flex-col gap-2 min-[480px]:gap-4 min-[480px]:w-min flex-1 rounded-lg" }
const _hoisted_2 = { class: "min-[768px]:text-nowrap text-white" }
const _hoisted_3 = { class: "min-[768px]:text-nowrap text-white" }




export default /*@__PURE__*/_defineComponent({
  __name: 'PostCustom',
  props: {
  title: { type: String, required: true },
  content: { type: [String, Number], required: true },
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, _toDisplayString(__props.title), 1),
    _createElementVNode("h6", _hoisted_3, _toDisplayString(__props.content), 1)
  ]))
}
}

})