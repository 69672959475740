import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "flex w-[100vw] min-h-[100vh] h-auto relative bg-[#f8f9fa]" }

import SideBarView from './views/SideBarView.vue';
import LoadingView from './views/LoadingView.vue';
import HeaderView from './views/HeaderView.vue';
import router from './router';
import { onMounted, ref, watch } from 'vue';
import { status, statusSideBar } from './store/global';


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

onMounted(() => {
  status.isPageLoaded = true;
});

watch(
  () => router.currentRoute.value.name,
  (newRouteName) => {
    isLoginRoute.value = newRouteName === 'login';
  }
);

const isLoginRoute = ref(router.currentRoute.value.name == 'login');

return (_ctx: any,_cache: any) => {
  const _component_RouterView = _resolveComponent("RouterView")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!isLoginRoute.value)
      ? (_openBlock(), _createBlock(SideBarView, {
          key: 0,
          class: _normalizeClass(["fixed top-0 min-[1200px]:left-0 z-50 transition-all", {
        '-left-full': !_unref(statusSideBar).visible,
        'left-0': _unref(statusSideBar).visible,
      }])
        }, null, 8, ["class"]))
      : _createCommentVNode("", true),
    (_unref(statusSideBar).visible)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "w-[100vw] h-full z-40 absolute backdrop-blur-[3px] bg-[rgba(0,0,0,0.4)]",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(statusSideBar).visible = false))
        }))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["flex flex-col w-full min-[1200px]:p-6 mb-4", {
        'min-[1200px]:pl-[290px] p-6  mb-4': !isLoginRoute.value,
        'p-0': isLoginRoute.value,
      }])
    }, [
      (!isLoginRoute.value)
        ? (_openBlock(), _createBlock(HeaderView, { key: 0 }))
        : _createCommentVNode("", true),
      _withDirectives(_createVNode(LoadingView, null, null, 512), [
        [_vShow, _unref(status).isApiLoading]
      ]),
      _withDirectives(_createElementVNode("div", null, [
        _createVNode(_component_RouterView)
      ], 512), [
        [_vShow, !_unref(status).isApiLoading]
      ])
    ], 2)
  ]))
}
}

})