import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex h-[100vh] w-[100vw] items-center justify-center p-4 bg-[#f8f9fa]" }
const _hoisted_2 = { class: "flex flex-col gap-4" }
const _hoisted_3 = { class: "flex flex-col gap-4" }
const _hoisted_4 = { class: "flex flex-col gap-2" }

import { ref } from 'vue';
import InputCustom from '@/components/InputCustom.vue';
import { authService } from '@/services';
import router from '@/router';


export default /*@__PURE__*/_defineComponent({
  __name: 'LoginView',
  setup(__props) {

const username = ref('');
const showErrorUserName = ref(false);
const password = ref('');
const showErrorPassword = ref(false);

const showErrorGeneral = ref(false);

const handleLogin = async () => {
  showErrorGeneral.value = false;
  if (validationUserLogin()) {
    try {
      const user = {
        name: username.value,
        password: password.value,
      };
      await authService.login(user);
      router.push('/funds');
    } catch (error) {
      showErrorGeneral.value = true;
      console.error('Login failed:', error);
    }
  }
};

function validationUserLogin() {
  showErrorUserName.value = false;
  showErrorPassword.value = false;
  if (username.value === '') {
    showErrorUserName.value = true;
  }
  if (password.value === '') {
    showErrorPassword.value = true;
  }

  if (showErrorUserName.value || showErrorPassword.value) return false;
  return true;
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("form", {
      onSubmit: _withModifiers(handleLogin, ["prevent"]),
      class: "flex w-[360px] flex-col gap-6 bg-white p-12 shadow-custom-shadow rounded-lg",
      autocomplete: "off"
    }, [
      _cache[3] || (_cache[3] = _createElementVNode("div", { class: "flex flex-col gap-2" }, [
        _createElementVNode("h4", { class: "text-primary" }, "Bienvenido"),
        _createElementVNode("p", null, "Ingrese su usuario y contraseña para iniciar sesión")
      ], -1)),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(InputCustom, {
            title: "Usuario",
            modelValue: username.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((username).value = $event)),
            type: "text",
            placeholder: "Usuario",
            "show-error": showErrorUserName.value
          }, null, 8, ["modelValue", "show-error"]),
          _createVNode(InputCustom, {
            title: "Contraseña",
            modelValue: password.value,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((password).value = $event)),
            type: "password",
            placeholder: "Contraseña",
            "show-error": showErrorPassword.value
          }, null, 8, ["modelValue", "show-error"])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("span", {
            class: _normalizeClass(["text-sm text-red-600 transition-all", {
              'scale-100': showErrorGeneral.value,
              'scale-0': !showErrorGeneral.value,
            }])
          }, "Usuario o contraseña incorrecto", 2),
          _cache[2] || (_cache[2] = _createElementVNode("button", {
            class: "w-full bg-primary text-white",
            type: "submit"
          }, "INICIAR", -1))
        ])
      ])
    ], 32)
  ]))
}
}

})