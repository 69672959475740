import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col gap-3 w-full" }
const _hoisted_2 = { class: "flex p-4 bg-custom-gradient-dark rounded-lg shadow-custom-shadow" }
const _hoisted_3 = { class: "flex flex-col gap-4 w-full" }
const _hoisted_4 = { class: "flex flex-wrap gap-3" }
const _hoisted_5 = { class: "flex flex-wrap gap-3" }
const _hoisted_6 = { class: "flex flex-wrap gap-3" }
const _hoisted_7 = { class: "flex flex-wrap gap-3 w-full justify-end" }

import PostCustom from '@/components/PostCustom.vue';
import { onMounted, ref, watch } from 'vue';
import { IFundDto } from '@/interfaces/dto';
import DeleteIcon from '@/components/icons/DeleteIcon.vue';
import DepositIcon from '@/components/icons/DepositIcon.vue';
import DeleteFund from './DeleteFund.vue';
import DepositFund from './DepositFund.vue';
import TransferIcon from '@/components/icons/TransferIcon.vue';
import TransferFund from './TransferFund.vue';
import WithdrawalIcon from '@/components/icons/WithdrawalIcon.vue';
import EditIcon from '@/components/icons/EditIcon.vue';
import WithdrawlFund from './WithdrawlFund.vue';
import EditFund from '@/views/fundsView/EditFund.vue';
import { hasPermission, permissions } from '@/store/RolesAndPermission';
import { UseLocaleTimeZone } from '@/helpers';


export default /*@__PURE__*/_defineComponent({
  __name: 'DetailsFund',
  props: {
    fund: {}
  },
  emits: ['fundDelete', 'refresh'],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emits = __emit;

const data = ref<IFundDto>(props.fund);
const showEdit = ref(false);
const showDelete = ref(false);
const showDeposit = ref(false);
const showTransfer = ref(false);
const showWithdrawal = ref(false);

const onUpdate = () => {
  showEdit.value = false;
  emits('refresh');
};
const closeDelete = () => {
  showDelete.value = false;
};
const deleteEmit = () => {
  showDelete.value = false;
  emits('fundDelete');
};
const closeDeposit = () => {
  showDeposit.value = false;
};
const depositEmit = async () => {
  showDeposit.value = false;
  emits('refresh');
};
const closeTransfer = () => {
  showTransfer.value = false;
};
const transferEmit = async () => {
  showTransfer.value = false;
  emits('refresh');
};
const closeWithdrawal = () => {
  showWithdrawal.value = false;
};
const withdrawalEmit = async () => {
  showWithdrawal.value = false;
  emits('refresh');
};

onMounted(() => {
  window.location.href = `${window.location.pathname}#details`;
});
watch(
  () => props.fund,
  (newFund) => {
    data.value = newFund;
  }
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[7] || (_cache[7] = _createElementVNode("h4", { class: "flex" }, "Detalles", -1)),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(PostCustom, {
              content: data.value.name,
              title: "Nombre"
            }, null, 8, ["content"]),
            _createVNode(PostCustom, {
              title: "Usuario",
              content: data.value.user?.username || 'No disponible'
            }, null, 8, ["content"]),
            _createVNode(PostCustom, {
              content: _unref(UseLocaleTimeZone).longDate(data.value.createAt),
              title: "Creado en"
            }, null, 8, ["content"]),
            _createVNode(PostCustom, {
              content: data.value.address || 'No disponible',
              title: "Dirección"
            }, null, 8, ["content"])
          ]),
          _createElementVNode("div", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data.value.currencies, (currency, index) => {
              return (_openBlock(), _createBlock(PostCustom, {
                key: index,
                title: currency.currency,
                content: currency.amount
              }, null, 8, ["title", "content"]))
            }), 128))
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(PostCustom, {
              title: "Detalles",
              content: data.value.details || 'No disponible'
            }, null, 8, ["content"])
          ]),
          _createElementVNode("div", _hoisted_7, [
            (data.value.currencies && data.value.currencies?.length > 0 && _unref(hasPermission)(_unref(permissions).actionTransf))
              ? (_openBlock(), _createElementBlock("i", {
                  key: 0,
                  class: "p-2 flex items-center justify-center rounded-lg hover:bg-blue-600 text-white h-min transition-all cursor-pointer hover:text-black",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (showTransfer.value = true))
                }, [
                  _createVNode(TransferIcon, { class: "w-6 h-6" })
                ]))
              : _createCommentVNode("", true),
            (_unref(hasPermission)(_unref(permissions).actionDeposit))
              ? (_openBlock(), _createElementBlock("i", {
                  key: 1,
                  class: "p-2 flex items-center justify-center rounded-lg hover:bg-green-600 text-white h-min transition-all cursor-pointer hover:text-black",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (showDeposit.value = true))
                }, [
                  _createVNode(DepositIcon, { class: "w-6 h-6" })
                ]))
              : _createCommentVNode("", true),
            (data.value.currencies && data.value.currencies?.length > 0 && _unref(hasPermission)(_unref(permissions).actionWithdrawl))
              ? (_openBlock(), _createElementBlock("i", {
                  key: 2,
                  class: "p-2 flex items-center justify-center rounded-lg hover:bg-primary text-white h-min transition-all cursor-pointer hover:text-black",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (showWithdrawal.value = true))
                }, [
                  _createVNode(WithdrawalIcon, { class: "w-6 h-6" })
                ]))
              : _createCommentVNode("", true),
            (_unref(hasPermission)(_unref(permissions).actionEdit))
              ? (_openBlock(), _createElementBlock("i", {
                  key: 3,
                  class: "p-2 flex items-center justify-center rounded-lg hover:bg-yellow-400 text-white h-min transition-all cursor-pointer hover:text-black",
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (showEdit.value = true))
                }, [
                  _createVNode(EditIcon, { class: "w-6 h-6" })
                ]))
              : _createCommentVNode("", true),
            (_unref(hasPermission)(_unref(permissions).actionDelete))
              ? (_openBlock(), _createElementBlock("i", {
                  key: 4,
                  class: "p-2 flex items-center justify-center rounded-lg hover:bg-red-600 text-white h-min transition-all cursor-pointer hover:text-black",
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (showDelete.value = true))
                }, [
                  _createVNode(DeleteIcon, { class: "w-6 h-6" })
                ]))
              : _createCommentVNode("", true)
          ])
        ])
      ])
    ]),
    (showEdit.value)
      ? (_openBlock(), _createBlock(EditFund, {
          key: 0,
          fund: data.value,
          onClose: _cache[5] || (_cache[5] = ($event: any) => (showEdit.value = false)),
          onOnUpdate: _cache[6] || (_cache[6] = ($event: any) => (onUpdate()))
        }, null, 8, ["fund"]))
      : _createCommentVNode("", true),
    (showDelete.value)
      ? (_openBlock(), _createBlock(DeleteFund, {
          key: 1,
          id: data.value.id,
          "close-delete": closeDelete,
          onFundDelete: deleteEmit
        }, null, 8, ["id"]))
      : _createCommentVNode("", true),
    (showDeposit.value)
      ? (_openBlock(), _createBlock(DepositFund, {
          key: 2,
          id: data.value.id,
          "close-deposit": closeDeposit,
          onFundDeposit: depositEmit
        }, null, 8, ["id"]))
      : _createCommentVNode("", true),
    (showTransfer.value)
      ? (_openBlock(), _createBlock(TransferFund, {
          key: 3,
          id: data.value.id,
          currencies: data.value.currencies,
          "close-transfer": closeTransfer,
          onFundTransfer: transferEmit
        }, null, 8, ["id", "currencies"]))
      : _createCommentVNode("", true),
    (showWithdrawal.value)
      ? (_openBlock(), _createBlock(WithdrawlFund, {
          key: 4,
          id: data.value.id,
          currencies: data.value.currencies,
          "close-withdrawal": closeWithdrawal,
          onFundWithdrawal: withdrawalEmit
        }, null, 8, ["id", "currencies"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})