import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "fixed flex h-[100vh] w-[100vw] items-center justify-center p-4 z-50 top-0 left-0 backdrop-blur-[3px] bg-[rgba(0,0,0,0.4)]" }
const _hoisted_2 = { class: "flex flex-col gap-4" }
const _hoisted_3 = { class: "flex flex-col gap-4" }
const _hoisted_4 = { class: "flex flex-col gap-2" }

import InputCustom from '@/components/InputCustom.vue';
import InputSelect from '@/components/InputSelect.vue';
import InputTextArea from '@/components/InputTextArea.vue';
import { ref } from 'vue';
import { IFundDto, IFundInfo, RoleType } from '@/interfaces/dto';
import { ICustomSelectOption } from '@/interfaces';
import { userService } from '@/services/userService';
import { fundService } from '@/services';


export default /*@__PURE__*/_defineComponent({
  __name: 'EditFund',
  props: {
    fund: {}
  },
  emits: ['onUpdate', 'close'],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;
const showErrorFundName = ref(false);
const showErrorGeneral = ref(false);
const errorText = ref('');
const model = ref<IFundInfo>({
  name: props.fund.name,
  address: props.fund.address || undefined,
  details: props.fund.details || undefined,
  locationUrl: props.fund.locationUrl || undefined,
});
const userSelect = ref<{ current: ICustomSelectOption<string>; data: ICustomSelectOption<string>[] }>({
  current: { value: props.fund.user?.id || '', text: props.fund.user?.username || '' },
  data: [{ value: '00000000-0000-0000-0000-000000000000', text: 'Desasignar' }],
});

async function fetchUsers(search: string[]) {
  const res = await userService.list(search, 0, 10, true).catch((error) => {
    showErrorGeneral.value = true;
    errorText.value = 'No se pudo obtener los datos de los usuarios';
    console.error('user fetch failed:', error);
    throw error;
  });
  userSelect.value.data = userSelect.value.data.concat(
    res.data.filter((user) => user.role === RoleType.Assessor).map((user) => ({ text: user.username, value: user.id }))
  );
}

const handleSubmit = async () => {
  showErrorGeneral.value = false;
  if (validationFundCreate()) {
    const fund = await fundService.update(props.fund.id, model.value).catch((error) => {
      showErrorGeneral.value = true;
      errorText.value = 'Ocurrió un error actualizando el fondo';
      console.error('Update failed:', error);
      throw error;
    });

    const userId = userSelect.value.current.value;
    if (userId !== '') {
      await fundService.attachUser(fund.id, userId).catch((error) => {
        showErrorGeneral.value = true;
        errorText.value = 'Ocurrió un error asignando el usuario al fondo';
        console.error('Attach User failed:', error);
        throw error;
      });
    }

    emit('onUpdate');
  }
};

function validationFundCreate() {
  showErrorFundName.value = model.value.name === '';
  return !showErrorFundName.value;
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("form", {
      class: "flex w-[500px] flex-col gap-6 bg-white p-12 shadow-custom-shadow rounded-lg",
      onSubmit: _withModifiers(handleSubmit, ["prevent"]),
      autocomplete: "off"
    }, [
      _cache[7] || (_cache[7] = _createElementVNode("div", { class: "flex flex-col gap-2" }, [
        _createElementVNode("h4", { class: "text-primary" }, "Actualizar Fondo")
      ], -1)),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(InputCustom, {
            modelValue: model.value.name,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((model.value.name) = $event)),
            "show-error": showErrorFundName.value,
            placeholder: "---",
            title: "Nombre",
            type: "text"
          }, null, 8, ["modelValue", "show-error"]),
          _createVNode(InputCustom, {
            modelValue: model.value.address,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((model.value.address) = $event)),
            placeholder: "---",
            title: "Dirección",
            type: "text"
          }, null, 8, ["modelValue"]),
          _createVNode(InputSelect, {
            "model-value": userSelect.value.current,
            options: userSelect.value.data,
            placeholder: "---",
            "show-search": "",
            title: "Asignar asesor",
            onEmitValues: _cache[2] || (_cache[2] = (value) => fetchUsers(value)),
            "onUpdate:modelValue": _cache[3] || (_cache[3] = (value) => (userSelect.value.current = value))
          }, null, 8, ["model-value", "options"]),
          _createVNode(InputTextArea, {
            modelValue: model.value.details,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((model.value.details) = $event)),
            placeholder: "---",
            title: "Detalles"
          }, null, 8, ["modelValue"])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("span", {
            class: _normalizeClass(["text-sm text-red-600 transition-all", {
              'scale-100': showErrorGeneral.value,
              'scale-0': !showErrorGeneral.value,
            }])
          }, _toDisplayString(errorText.value), 3),
          _cache[6] || (_cache[6] = _createElementVNode("button", {
            class: "w-full bg-primary text-white",
            type: "submit"
          }, "ACTUALIZAR", -1)),
          _createElementVNode("button", {
            class: "w-full bg-white text-primary border-primary border-solid border-[1px] text-nowrap",
            type: "button",
            onClick: _cache[5] || (_cache[5] = ($event: any) => (emit('close')))
          }, " CERRAR ")
        ])
      ])
    ], 32)
  ]))
}
}

})