import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "fixed flex h-[100vh] w-[100vw] items-center justify-center max-sm:p-0 max-sm:rounded-none p-4 z-50 top-0 left-0 backdrop-blur-[3px] bg-[rgba(0,0,0,0.4)]"
}
const _hoisted_2 = { class: "flex flex-col gap-4" }
const _hoisted_3 = { class: "flex flex-col gap-4" }
const _hoisted_4 = { class: "flex flex-col gap-2" }
const _hoisted_5 = { class: "flex gap-3" }

import { onMounted, Ref, ref } from 'vue';
import InputSelect from '@/components/InputSelect.vue';
import InputMultiSelect from '@/components/InputMultiSelect.vue';
import { currencyService, fundService } from '@/services';
import { IFundFilter } from '@/interfaces/dto';
import CustomCheckBox from '@/components/CustomCheckBox.vue';
import { userService } from '@/services/userService';

/* filter model */
interface option {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
  text: string;
}

/* orderBy */

export default /*@__PURE__*/_defineComponent({
  __name: 'FiltersFund',
  props: {
    closeFilter: { type: Function },
    showFilter: { type: Boolean }
  },
  emits: ['filterValue', 'restartFilterValue'],
  setup(__props: any, { emit: __emit }) {

/* import */
const filter = ref<IFundFilter>({ descending: true });

const optionSelectOrderBy: Ref<option> = ref({ value: null, text: '' });
const optionsOrderBy: Ref<option[]> = ref([
  { value: 'funds', text: 'Fondos' },
  { value: 'usernames', text: 'Usuarios' },
  { value: 'create_at', text: 'Fecha de creacion' },
]);

/* fundsNames */
const optionSelectFundName: Ref<option[]> = ref([]);
const optionsFundsNames: Ref<option[]> = ref([]);

const fetchFundsNames = async () => {
  try {
    const res = await fundService.list({ fundNames: filter.value.fundNames }, 0, 10);
    optionsFundsNames.value = res.data.map((fund) => ({
      value: fund.name,
      text: fund.name,
    }));
  } catch (error) {
    showErrorGeneral.value = true;
    console.error(error);
  }
};

/* usernames */
const optionSelectUsername: Ref<option[]> = ref([]);
const optionsUsersnames: Ref<option[]> = ref([]);

const fetchUsernames = async () => {
  try {
    const res = await userService.list(filter.value.usernames, 0, 10);
    optionsUsersnames.value = res.data.map((user) => ({
      value: user.username,
      text: user.username,
    }));
  } catch (error) {
    showErrorGeneral.value = true;
    console.error(error);
  }
};

/* currencies */
const optionSelectCurrenci: Ref<option[]> = ref([]);
const optionsCurrencies: Ref<option[]> = ref([]);

const fetchCurrencies = async () => {
  try {
    const res = await currencyService.list();
    optionsCurrencies.value = res.map((currency) => ({
      value: currency.id,
      text: currency.currency,
    }));
  } catch (error) {
    showErrorGeneral.value = true;
    console.error(error);
  }
};

/* Validation const */
const showErrorGeneral: Ref<boolean> = ref(false);
const errorText: Ref<string> = ref('Hubo un error obteniendo los datos');

/* props and emits*/


// eslint-disable-next-line @typescript-eslint/no-unused-vars
const emit = __emit;

const resetFilters = () => {
  filter.value = { descending: true };

  optionSelectFundName.value = [];
  optionSelectUsername.value = [];
  optionSelectCurrenci.value = [];
  optionSelectOrderBy.value = { value: null, text: '' };

  showErrorGeneral.value = false;
  console.log(optionSelectOrderBy.value);
  emit('restartFilterValue', null);
};

/* hooks */
onMounted(() => {
  fetchCurrencies();
  fetchFundsNames();
  fetchUsernames();
});

return (_ctx: any,_cache: any) => {
  return (_ctx.showFilter)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("form", {
          class: "flex justify-center max-sm:h-[100vh] w-[600px] max-sm:w-full flex-col gap-6 bg-white p-12 shadow-custom-shadow rounded-lg",
          onSubmit: _cache[11] || (_cache[11] = _withModifiers(($event: any) => (emit('filterValue', filter.value)), ["prevent"])),
          autocomplete: "off"
        }, [
          _cache[13] || (_cache[13] = _createElementVNode("div", { class: "flex flex-col gap-2" }, [
            _createElementVNode("h4", { class: "text-primary" }, "Filtrar Fondos")
          ], -1)),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(InputMultiSelect, {
                "model-value": optionSelectFundName.value,
                options: optionsFundsNames.value,
                "show-search": true,
                title: "Fondos",
                placeholder: "---",
                onEmitValues: _cache[0] || (_cache[0] = async (value) => ((filter.value.fundNames = value), await fetchFundsNames())),
                "onUpdate:modelValue": _cache[1] || (_cache[1] = (value) => (optionSelectFundName.value = value))
              }, null, 8, ["model-value", "options"]),
              _createVNode(InputMultiSelect, {
                "model-value": optionSelectUsername.value,
                options: optionsUsersnames.value,
                "show-search": true,
                placeholder: "---",
                title: "Usuarios",
                onEmitValues: _cache[2] || (_cache[2] = async (value) => ((filter.value.usernames = value), await fetchUsernames())),
                "onUpdate:modelValue": _cache[3] || (_cache[3] = (value) => (optionSelectUsername.value = value))
              }, null, 8, ["model-value", "options"]),
              _createVNode(InputMultiSelect, {
                title: "Monedas",
                "model-value": optionSelectCurrenci.value,
                options: optionsCurrencies.value,
                placeholder: "---",
                onEmitValues: _cache[4] || (_cache[4] = (value) => (filter.value.currencies = value)),
                "onUpdate:modelValue": _cache[5] || (_cache[5] = (value) => (optionSelectCurrenci.value = value))
              }, null, 8, ["model-value", "options"]),
              _createVNode(InputSelect, {
                title: "Ordenar por",
                "model-value": optionSelectOrderBy.value,
                options: optionsOrderBy.value,
                onEmitValue: _cache[6] || (_cache[6] = (value) => (filter.value.orderBy = value)),
                "onUpdate:modelValue": _cache[7] || (_cache[7] = (value) => (optionSelectOrderBy.value = value))
              }, null, 8, ["model-value", "options"]),
              _createVNode(CustomCheckBox, {
                "default-value": filter.value.descending,
                title: "Ordenar descendiente",
                onReturnValue: _cache[8] || (_cache[8] = (value) => (filter.value.descending = value))
              }, null, 8, ["default-value"])
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("span", {
                class: _normalizeClass(["text-sm text-red-600 transition-all", {
              'scale-100': showErrorGeneral.value,
              'scale-0': !showErrorGeneral.value,
            }])
              }, _toDisplayString(errorText.value), 3),
              _cache[12] || (_cache[12] = _createElementVNode("button", {
                class: "w-full bg-primary text-white",
                type: "submit"
              }, "FILTRAR", -1)),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("button", {
                  onClick: _cache[9] || (_cache[9] = ($event: any) => (resetFilters())),
                  type: "button",
                  class: "w-full bg-[#717ef5] text-white flex-1"
                }, " RESTABLECER "),
                _createElementVNode("button", {
                  onClick: _cache[10] || (_cache[10] = 
//@ts-ignore
(...args) => (_ctx.closeFilter && _ctx.closeFilter(...args))),
                  type: "button",
                  class: "w-full bg-white text-primary border-primary border-solid border-[1px] text-nowrap flex-1"
                }, " CERRAR ")
              ])
            ])
          ])
        ], 32)
      ]))
    : _createCommentVNode("", true)
}
}

})