import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "fixed flex h-[100vh] w-[100vw] items-center justify-center p-4 z-50 top-0 left-0 backdrop-blur-[3px] bg-[rgba(0,0,0,0.4)]" }
const _hoisted_2 = { class: "flex flex-col gap-4" }
const _hoisted_3 = { class: "flex flex-col gap-2" }
const _hoisted_4 = { class: "flex items-center" }
const _hoisted_5 = {
  key: 0,
  class: "ml-2 transition"
}

import { onMounted, ref, watch } from 'vue';
import { currencyService, fundService } from '@/services';
import InputSelect from '@/components/InputSelect.vue';
import InputCustom from '@/components/InputCustom.vue';
import InputTextArea from '@/components/InputTextArea.vue';
import { ITransactionInfoDto } from '@/interfaces/dto';
import { ICustomSelectOption } from '@/interfaces/ICustomSelectOption';
import SpinnerLoading from '@/components/SpinnerLoading.vue';

/* Validation const */

export default /*@__PURE__*/_defineComponent({
  __name: 'DepositFund',
  props: {
    closeDeposit: { type: Function },
    id: {}
  },
  emits: ['fundDeposit'],
  setup(__props: any, { emit: __emit }) {

/* import */
const showErrorGeneral = ref(false);
const errorText = ref('Ocurrio un error');
const inputCurrency = ref(0);
const showErrorInputCurrency = ref(false);
const showErrorSelectCurrency = ref(false);
const textErrorInputCurrency = ref('El valor debe ser mayor que 0');
const details = ref('');

/* props and emits*/
const props = __props;

const isLoading = ref(false);

const emit = __emit;

/* Deposit */
const handleDeposit = async () => {
  showErrorGeneral.value = false;
  isLoading.value = true;
  if (validation()) {
    try {
      const transaction: ITransactionInfoDto = {
        source: props.id,
        currency: optionSelectCurrency.value.value,
        details: details.value == '' ? null : details.value,
        amount: inputCurrency.value,
      };
      console.log(transaction);
      await fundService.deposit(transaction);
      restart();

      emit('fundDeposit');
    } catch (error) {
      showErrorGeneral.value = true;
      console.error('Deposit failed:', error);
    }
  }
  isLoading.value = false;
};

const validation = () => {
  showErrorInputCurrency.value = false;
  showErrorSelectCurrency.value = false;

  if (inputCurrency.value < 1) {
    showErrorInputCurrency.value = true;
    inputCurrency.value = 0;
  }
  if (optionSelectCurrency.value.value == '') {
    showErrorSelectCurrency.value = true;
  }
  return !(showErrorInputCurrency.value || showErrorSelectCurrency.value);
};

const restart = () => {
  inputCurrency.value = 0;
  showErrorInputCurrency.value = false;
  showErrorSelectCurrency.value = false;
  details.value = '';
  optionSelectCurrency.value = { value: '', text: '' };
  showErrorGeneral.value = false;
};

/* currencies */
const optionSelectCurrency = ref<ICustomSelectOption<string>>({ value: '', text: '' });
const optionsCurrencies = ref<ICustomSelectOption<string>[]>([]);

const fetchCurrencies = async () => {
  try {
    const res = await currencyService.list();
    optionsCurrencies.value = res
      .map((currency) => ({
        value: currency.id,
        text: currency.currency,
      }))
      .filter((option) => option.value !== '');
  } catch (error) {
    showErrorGeneral.value = true;
    console.error(error);
  }
};

onMounted(() => {
  fetchCurrencies();
  restart();
});

watch(
  () => props.id,
  (newId) => {
    if (newId) {
      restart();
    }
  }
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("form", {
      onSubmit: _withModifiers(handleDeposit, ["prevent"]),
      class: "flex w-[360px] flex-col gap-6 bg-white p-12 shadow-custom-shadow rounded-lg",
      autocomplete: "off"
    }, [
      _cache[5] || (_cache[5] = _createElementVNode("div", { class: "flex flex-col gap-2" }, [
        _createElementVNode("h4", { class: "text-primary" }, "Depositar")
      ], -1)),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(InputSelect, {
          "model-value": optionSelectCurrency.value,
          options: optionsCurrencies.value,
          title: "Moneda",
          "show-error": showErrorSelectCurrency.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = (value) => (optionSelectCurrency.value = value))
        }, null, 8, ["model-value", "options", "show-error"]),
        _createVNode(InputCustom, {
          modelValue: inputCurrency.value,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((inputCurrency).value = $event)),
          title: "Cantidad",
          type: "number",
          "show-error": showErrorInputCurrency.value,
          "-error-text": textErrorInputCurrency.value
        }, null, 8, ["modelValue", "show-error", "-error-text"]),
        _createVNode(InputTextArea, {
          modelValue: details.value,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((details).value = $event)),
          title: "Detalles"
        }, null, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", {
          class: _normalizeClass(["text-sm text-red-600 transition-all", {
            'scale-100': showErrorGeneral.value,
            'scale-0': !showErrorGeneral.value,
          }])
        }, _toDisplayString(errorText.value), 3),
        _createElementVNode("div", _hoisted_4, [
          _cache[4] || (_cache[4] = _createElementVNode("button", {
            class: "w-full bg-primary text-white",
            type: "submit"
          }, "DEPOSITAR", -1)),
          (isLoading.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createVNode(SpinnerLoading)
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("button", {
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.closeDeposit())),
          type: "button",
          class: "w-full bg-white text-primary border-primary border-solid border-[1px] text-nowrap"
        }, " CERRAR ")
      ])
    ], 32)
  ]))
}
}

})