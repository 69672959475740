import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex items-center justify-between" }
const _hoisted_2 = { key: 0 }

import { ref } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'CustomCheckBox',
  props: {
    defaultValue: { type: Boolean },
    title: {}
  },
  emits: ['returnValue'],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const value = ref<boolean>(props.defaultValue);
const emit = __emit;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.title !== '')
      ? (_openBlock(), _createElementBlock("label", _hoisted_2, _toDisplayString(props.title), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      onClick: _cache[0] || (_cache[0] = 
        () => {
          value.value = !value.value;
          emit('returnValue', value.value);
        }
      ),
      class: _normalizeClass([{ active: value.value }, "checkbox-container"])
    }, _cache[1] || (_cache[1] = [
      _createElementVNode("div", null, null, -1)
    ]), 2)
  ]))
}
}

})