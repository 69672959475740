import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "h-[2px] flex w-full justify-end items-center" }

import MenuIcon from '@/components/icons/MenuIcon.vue';
import { statusSideBar } from '@/store/global';

export default /*@__PURE__*/_defineComponent({
  __name: 'HeaderView',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("i", {
      class: _normalizeClass(["w-10 h-10 p-[10px] min-[1200px]:hidden items-center justify-center rounded-lg fixed bottom-4 right-4 z-50 flex overflow-hidden", {
        'shadow-none bg-custom-gradient-icon-active text-white': _unref(statusSideBar).visible,
        'shadow-custom-shadow-icon bg-custom-gradient-icon text-[#344767]': !_unref(statusSideBar).visible,
      }]),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(statusSideBar).visible = !_unref(statusSideBar).visible))
    }, [
      _createVNode(MenuIcon)
    ], 2)
  ]))
}
}

})