import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "fixed flex h-[100vh] w-[100vw] items-center justify-center p-4 z-50 top-0 left-0 backdrop-blur-[3px] bg-[rgba(0,0,0,0.4)]" }
const _hoisted_2 = { class: "flex flex-col gap-4" }
const _hoisted_3 = { class: "flex flex-col gap-4" }
const _hoisted_4 = { class: "flex flex-col gap-2" }
const _hoisted_5 = { class: "flex items-center" }
const _hoisted_6 = {
  key: 0,
  class: "ml-2 transition"
}

import { ref } from 'vue';
import InputCustom from '@/components/InputCustom.vue';
import { IEditUserDto } from '@/interfaces/dto';
import { userService } from '@/services/userService';
import SpinnerLoading from '@/components/SpinnerLoading.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'EditUser',
  props: {
    data: {}
  },
  emits: ['userEdit', 'close'],
  setup(__props: any, { emit: __emit }) {

const emit = __emit;
const props = __props;
const isLoading = ref(false);

const model = ref<IEditUserDto>({ id: props.data.id, username: props.data.username, email: props.data.email });
const errorText = ref('Hubo un error creando el usuario');
const showError = ref({
  general: false,
  username: false,
  email: false,
});
const errorMessages = ref({
  username: '',
  email: '',
});

const handleEdit = async () => {
  showError.value.general = false;
  isLoading.value = true;
  if (validationUserCreate()) {
    try {
      await userService.update(model.value);
      emit('userEdit');
    } catch (error) {
      showError.value.general = true;
      console.error('Edit failed:', error);
    }
  }
  isLoading.value = false;
};

const validationUserCreate = () => {
  showError.value = {
    general: false,
    username: false,
    email: false,
  };

  if (!model.value.username) {
    errorMessages.value.username = 'Este campo es requerido';
    showError.value.username = true;
  } else if (model.value.username.length < 2) {
    errorMessages.value.username = 'El nombre debe tener más de dos caracteres';
    showError.value.username = true;
  } else {
    showError.value.username = false;
  }

  if (!model.value.email) {
    errorMessages.value.email = 'Este campo es requerido';
    showError.value.email = true;
  } else if (!validateEmail(model.value.email)) {
    errorMessages.value.email = 'El correo debe ser válido';
    showError.value.email = true;
  } else {
    showError.value.email = false;
  }

  return !Object.values(showError.value).includes(true);
  function validateEmail(email: string) {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("form", {
      onSubmit: _withModifiers(handleEdit, ["prevent"]),
      class: "flex w-[500px] flex-col gap-6 bg-white p-12 shadow-custom-shadow rounded-lg",
      autocomplete: "off"
    }, [
      _cache[4] || (_cache[4] = _createElementVNode("div", { class: "flex flex-col gap-2" }, [
        _createElementVNode("h4", { class: "text-primary" }, "Editar Usuario")
      ], -1)),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(InputCustom, {
            modelValue: model.value.username,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((model.value.username) = $event)),
            "show-error": showError.value.username,
            "-error-text": errorMessages.value.username,
            placeholder: "---",
            title: "Nombre",
            type: "text"
          }, null, 8, ["modelValue", "show-error", "-error-text"]),
          _createVNode(InputCustom, {
            modelValue: model.value.email,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((model.value.email) = $event)),
            "show-error": showError.value.email,
            "-error-text": errorMessages.value.email,
            placeholder: "---",
            title: "Correo",
            type: "text"
          }, null, 8, ["modelValue", "show-error", "-error-text"])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("span", {
            class: _normalizeClass(["text-sm text-red-600 transition-all", {
              'scale-100': showError.value.general,
              'scale-0': !showError.value.general,
            }])
          }, _toDisplayString(errorText.value), 3),
          _createElementVNode("div", _hoisted_5, [
            _cache[3] || (_cache[3] = _createElementVNode("button", {
              class: "w-full bg-primary text-white",
              type: "submit"
            }, "ACTUALIZAR", -1)),
            (isLoading.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createVNode(SpinnerLoading)
                ]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("button", {
            onClick: _cache[2] || (_cache[2] = ($event: any) => (emit('close'))),
            type: "button",
            class: "w-full bg-white text-primary border-primary border-solid border-[1px] text-nowrap"
          }, " CERRAR ")
        ])
      ])
    ], 32)
  ]))
}
}

})