import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "fixed flex h-[100vh] w-[100vw] items-center justify-center p-4 z-50 top-0 left-0 backdrop-blur-[3px] bg-[rgba(0,0,0,0.4)]" }
const _hoisted_2 = { class: "flex flex-col gap-2" }
const _hoisted_3 = { class: "flex items-center" }
const _hoisted_4 = {
  key: 0,
  class: "ml-2 transition"
}

import { ref } from 'vue';
import { userService } from '@/services/userService';
import SpinnerLoading from '@/components/SpinnerLoading.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'DeleteUser',
  props: {
    userId: {}
  },
  emits: ['close', 'onDeleted'],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const [showError, isLoading] = [ref(false), ref(false)];

async function deleteUser() {
  isLoading.value = true;
  await userService.delete(props.userId).catch((error) => {
    showError.value = true;
    console.error('Delete User failed:', error);
  });
  isLoading.value = false;
  emit('onDeleted');
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("form", {
      autocomplete: "off",
      class: "flex w-[360px] flex-col gap-6 bg-white p-12 shadow-custom-shadow rounded-lg",
      onSubmit: _withModifiers(deleteUser, ["prevent"])
    }, [
      _cache[2] || (_cache[2] = _createElementVNode("div", { class: "flex flex-col gap-2" }, [
        _createElementVNode("h4", { class: "text-primary" }, "Eliminar Usuario")
      ], -1)),
      _cache[3] || (_cache[3] = _createElementVNode("div", { class: "flex flex-col gap-4" }, [
        _createElementVNode("p", null, "Esta seguro que desea eliminar este usuario?")
      ], -1)),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("span", {
          class: _normalizeClass([{
            'scale-100': _unref(showError),
            'scale-0': !_unref(showError),
          }, "text-sm text-red-600 transition-all"])
        }, _toDisplayString(_ctx.errorText), 3),
        _createElementVNode("div", _hoisted_3, [
          _cache[1] || (_cache[1] = _createElementVNode("button", {
            class: "w-full bg-primary text-white",
            type: "submit"
          }, "ELIMINAR", -1)),
          (_unref(isLoading))
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(SpinnerLoading)
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("button", {
          class: "w-full bg-white text-primary border-primary border-solid border-[1px] text-nowrap",
          type: "button",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (emit('close')))
        }, " CANCELAR ")
      ])
    ], 32)
  ]))
}
}

})