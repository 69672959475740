import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "fixed flex h-[100vh] w-[100vw] items-center justify-center p-4 z-50 top-0 left-0 backdrop-blur-[3px] bg-[rgba(0,0,0,0.4)]" }
const _hoisted_2 = { class: "flex flex-col gap-2" }
const _hoisted_3 = { class: "flex items-center" }
const _hoisted_4 = {
  key: 0,
  class: "ml-2 transition"
}

import { ref } from 'vue';
import { fundService } from '@/services';
import SpinnerLoading from '@/components/SpinnerLoading.vue';

/* Validation const */

export default /*@__PURE__*/_defineComponent({
  __name: 'DeleteFund',
  props: {
    closeDelete: { type: Function },
    id: {}
  },
  emits: ['fundDelete'],
  setup(__props: any, { emit: __emit }) {

/* import */
const showErrorGeneral = ref(false);
const errorText = ref('Hubo un error eliminando el fondo');
const isLoading = ref(false);

/* props and emits*/
const props = __props;

const emit = __emit;

/* Add */
const handleDelete = async () => {
  showErrorGeneral.value = false;
  isLoading.value = true;
  try {
    await fundService.delete(props.id);
    emit('fundDelete');
  } catch (error) {
    showErrorGeneral.value = true;
    console.error('Create failed:', error);
  }
  isLoading.value = false;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("form", {
      onSubmit: _withModifiers(handleDelete, ["prevent"]),
      class: "flex w-[360px] flex-col gap-6 bg-white p-12 shadow-custom-shadow rounded-lg",
      autocomplete: "off"
    }, [
      _cache[2] || (_cache[2] = _createElementVNode("div", { class: "flex flex-col gap-2" }, [
        _createElementVNode("h4", { class: "text-primary" }, "Eliminar Fondo")
      ], -1)),
      _cache[3] || (_cache[3] = _createElementVNode("div", { class: "flex flex-col gap-4" }, [
        _createElementVNode("p", null, "Esta seguro que desea eliminar el fondo?")
      ], -1)),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("span", {
          class: _normalizeClass(["text-sm text-red-600 transition-all", {
            'scale-100': showErrorGeneral.value,
            'scale-0': !showErrorGeneral.value,
          }])
        }, _toDisplayString(errorText.value), 3),
        _createElementVNode("div", _hoisted_3, [
          _cache[1] || (_cache[1] = _createElementVNode("button", {
            class: "w-full bg-primary text-white",
            type: "submit"
          }, "ELIMINAR", -1)),
          (isLoading.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(SpinnerLoading)
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("button", {
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeDelete && _ctx.closeDelete(...args))),
          type: "button",
          class: "w-full bg-white text-primary border-primary border-solid border-[1px] text-nowrap"
        }, " CANCELAR ")
      ])
    ], 32)
  ]))
}
}

})