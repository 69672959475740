import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col gap-6" }
const _hoisted_2 = { class: "flex justify-between items-center gap-8 shadow-custom-shadow bg-white p-3 rounded-lg flex-wrap" }
const _hoisted_3 = { class: "flex gap-3 items-center flex-wrap" }
const _hoisted_4 = { key: 1 }

import TableCustom from '@/components/TableCustom.vue';
import { ref, watch } from 'vue';
import { hasPermission, permissions } from '@/store/RolesAndPermission';


export default /*@__PURE__*/_defineComponent({
  __name: 'ListModal',
  props: {
  name: {
    type: String,
    required: true,
  },
  addEnabled: {
    type: Boolean,
    default: false,
  },
  filterEnabled: {
    type: Boolean,
    default: true,
  },
  enabledNext: {
    type: Boolean,
  },
  enabledBack: {
    type: Boolean,
  },
  showAdd: {
    type: Function as unknown as () => ((payload: MouseEvent) => void) | undefined,
  },
  showFilter: {
    type: Function as unknown as () => ((payload: MouseEvent) => void) | undefined,
  },
  nextPage: {
    type: Function as unknown as () => ((payload: MouseEvent) => void) | undefined,
  },
  backPage: {
    type: Function as unknown as () => ((payload: MouseEvent) => void) | undefined,
  },
  startPage: {
    type: Function as unknown as () => ((payload: MouseEvent) => void) | undefined,
  },
  endPage: {
    type: Function as unknown as () => ((payload: MouseEvent) => void) | undefined,
  },
  header: {
    type: Array as () => string[],
    required: true,
  },
  keys: {
    type: Array as () => string[],
    required: true,
  },
  hiddenMobile: {
    type: Array as () => number[],
    default: Array as () => number[],
  },
  pageCurrent: {
    type: Number,
  },
  totalPage: {
    type: Number,
  },
  data: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    type: Array as () => any[],
    required: true,
  },
  isLoading: {
    type: Boolean,
    required: true,
  },
  placeholder: {
    type: String,
    default: 'Buscar...',
  },
  buttonLabel: {
    type: String,
    default: 'AGREGAR',
  },
  filterLabel: {
    type: String,
    default: 'FILTROS',
  },
},
  emits: ['returnId', 'returnItem'],
  setup(__props, { emit: __emit }) {

const props = __props;

const showFilterInternal = ref(true);
const hiddeTable = ref(false);

const emit = __emit;
const idValue = (id: string) => {
  emit('returnId', id);
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const value = (item: any) => {
  emit('returnItem', item);
};

watch(
  () => props.data,
  (newData) => {
    if (newData.length == 1) {
      showFilterInternal.value = false && props.pageCurrent == 1;
      hiddeTable.value = true && props.pageCurrent == 1;
      let item = newData[0];
      emit('returnId', item.id);
      emit('returnItem', item);
    } else {
      (showFilterInternal.value = true), (hiddeTable.value = false);
    }
  },
  { immediate: true }
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h4", null, _toDisplayString(props.name), 1),
      _createElementVNode("div", {
        class: _normalizeClass(["flex items-center flex-wrap gap-3", {
          'justify-between': props.addEnabled && _unref(hasPermission)(_unref(permissions).add),
          'justify-end': !props.addEnabled || !_unref(hasPermission)(_unref(permissions).add),
        }])
      }, [
        (props.addEnabled && _unref(hasPermission)(_unref(permissions).add))
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              class: "bg-primary text-white w-min text-nowrap",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (__props.showAdd && __props.showAdd(...args)))
            }, _toDisplayString(props.buttonLabel), 1))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_3, [
          (showFilterInternal.value && props.filterEnabled)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (__props.showFilter && __props.showFilter(...args))),
                class: "bg-white text-primary border-primary border-solid border-[1px] w-min text-nowrap"
              }, _toDisplayString(props.filterLabel), 1))
            : (_openBlock(), _createElementBlock("div", _hoisted_4))
        ])
      ], 2)
    ]),
    (!hiddeTable.value)
      ? (_openBlock(), _createBlock(TableCustom, {
          key: 0,
          headers: props.header,
          data: props.data,
          keys: props.keys,
          loading: props.isLoading,
          "hidden-mobile": props.hiddenMobile,
          "next-page": __props.nextPage,
          "start-page": __props.startPage,
          "back-page": __props.backPage,
          "end-page": __props.endPage,
          "total-page": __props.totalPage,
          "page-current": __props.pageCurrent,
          "enabled-next": __props.enabledNext,
          "enabled-back": __props.enabledBack,
          onReturnId: idValue,
          onReturnItem: value
        }, null, 8, ["headers", "data", "keys", "loading", "hidden-mobile", "next-page", "start-page", "back-page", "end-page", "total-page", "page-current", "enabled-next", "enabled-back"]))
      : _createCommentVNode("", true)
  ]))
}
}

})