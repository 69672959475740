<template>
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_4888_773)">
      <path
        d="M18.7915 17.2302C15.6447 16.0838 14.6387 15.1161 14.6387 13.0442C14.6387 11.8008 15.6698 10.995 16.0212 9.92932C16.3727 8.86366 16.5761 7.60198 16.7451 6.6842C16.9141 5.76642 16.9812 5.41144 17.0731 4.43356C17.1855 3.21314 16.3685 0.0625 12 0.0625C7.63278 0.0625 6.81318 3.21314 6.92808 4.43356C7.02 5.41144 7.08752 5.76646 7.25616 6.6842C7.42482 7.60196 7.62616 8.86358 7.97742 9.92932C8.32866 10.995 9.36122 11.8008 9.36122 13.0442C9.36122 15.1161 8.35526 16.0838 5.20846 17.2302C2.05022 18.3791 0 19.5121 0 20.3125V24.0625H24V20.3125C24 19.5134 21.9485 18.3804 18.7915 17.2302Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_4888_773">
        <rect width="24" height="24" fill="white" transform="translate(0 0.0625)" />
      </clipPath>
    </defs>
  </svg>
</template>
