import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, vShow as _vShow } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "ml-1 mb-2"
}
const _hoisted_2 = { class: "relative" }
const _hoisted_3 = { class: "absolute z-10 mt-1 w-full bg-white shadow-custom-shadow max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = {
  key: 1,
  class: "text-sm text-red-600"
}

import { onBeforeUnmount, onMounted, ref, watch } from 'vue';
import { ICustomSelectOption } from '@/interfaces/ICustomSelectOption';


export default /*@__PURE__*/_defineComponent({
  __name: 'InputSelect',
  props: {
  title: {
    type: String,
  },
  options: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    type: Array as () => { value: any; text: string }[],
    required: true,
  },
  modelValue: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    type: Object as () => ICustomSelectOption<string>,
    default: () => ({ value: '', text: '' }),
  },
  showError: {
    type: Boolean,
    default: false,
  },
  ErrorText: {
    type: String,
    default: 'Este campo es requerido',
  },
  placeholder: {
    type: String,
    default: 'Seleccione una opción',
  },
  showSearch: {
    type: Boolean,
    default: false,
  },
},
  emits: ['update:modelValue', 'emitValue', 'emitValues'],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;
const internalValue = ref(props.modelValue.value);
const isOpen = ref(false);
const selectedOptionText = ref(props.modelValue.text);
const dropdown = ref<HTMLElement | null>(null);
const searchQuery = ref('');

const checkDefaultValue = () => {
  const selectedOption = props.options.find((option) => option.value === props.modelValue.value);
  if (selectedOption) {
    internalValue.value = props.modelValue.value;
    selectedOptionText.value = selectedOption.text;
  }
};

const handleClickOutside = (event: MouseEvent) => {
  if (dropdown.value && !dropdown.value.contains(event.target as Node) && isOpen.value) {
    isOpen.value = false;
    searchQuery.value = '';
  }
};

onMounted(() => {
  checkDefaultValue();
  document.addEventListener('click', handleClickOutside);
});

onBeforeUnmount(() => {
  document.removeEventListener('click', handleClickOutside);
});

watch(
  () => props.modelValue,
  (newValue) => {
    internalValue.value = newValue.value;
    selectedOptionText.value = newValue.text;
    emit('emitValue', newValue.value); // Emit the value when it changes
  }
);

watch(internalValue, (newValue) => {
  emit('update:modelValue', { value: newValue, text: selectedOptionText.value });
  const selectedOption = props.options.find((option) => option.value === newValue);
  selectedOptionText.value = selectedOption ? selectedOption.text : '';
});

const toggleDropdown = () => {
  isOpen.value = !isOpen.value;
  searchQuery.value = '';
  if (selectedOptionText.value != '') {
    emit('emitValues', [selectedOptionText.value, searchQuery.value]);
  } else {
    emit('emitValues', [searchQuery.value]);
  }
};
const emitSearch = () => {
  if (selectedOptionText.value != '') {
    emit('emitValues', [selectedOptionText.value, searchQuery.value]);
  } else {
    emit('emitValues', [searchQuery.value]);
  }
};

const selectOption = (option: { value: string | undefined; text: string }) => {
  if (internalValue.value === option.value) {
    internalValue.value = '';
    selectedOptionText.value = '';
  } else {
    internalValue.value = option.value;
    selectedOptionText.value = option.text;
  }
  emit('emitValue', option.value);
  isOpen.value = false;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "flex flex-col relative",
    ref_key: "dropdown",
    ref: dropdown
  }, [
    (__props.title)
      ? (_openBlock(), _createElementBlock("label", _hoisted_1, _toDisplayString(__props.title), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", {
        type: "button",
        onClick: toggleDropdown,
        class: "text text-start selectInput"
      }, [
        _createTextVNode(_toDisplayString(selectedOptionText.value || __props.placeholder) + " ", 1),
        _cache[1] || (_cache[1] = _createElementVNode("span", { class: "absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none" }, [
          _createElementVNode("svg", {
            class: "h-5 w-5 text-gray-400",
            viewBox: "0 0 20 20",
            fill: "currentColor"
          }, [
            _createElementVNode("path", {
              "fill-rule": "evenodd",
              d: "M10 3a1 1 0 01.707.293l5 5a1 1 0 01-1.414 1.414L10 5.414 5.707 9.707A1 1 0 014.293 8.293l5-5A1 1 0 0110 3z",
              "clip-rule": "evenodd"
            })
          ])
        ], -1))
      ]),
      _withDirectives(_createElementVNode("ul", _hoisted_3, [
        (__props.showSearch)
          ? _withDirectives((_openBlock(), _createElementBlock("input", {
              key: 0,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((searchQuery).value = $event)),
              onInput: emitSearch,
              type: "text",
              placeholder: "Buscar...",
              class: "w-full border-none focus:border-none focus:shadow-none"
            }, null, 544)), [
              [_vModelText, searchQuery.value]
            ])
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.options, (option) => {
          return (_openBlock(), _createElementBlock("li", {
            key: option.value,
            onClick: ($event: any) => (selectOption(option)),
            class: "cursor-pointer select-none relative py-2 pl-3 pr-9 hover:bg-[#f58e713a] hover:text-[#495057]"
          }, [
            _createElementVNode("span", {
              class: _normalizeClass([{
              'font-semibold': option.value === internalValue.value,
              'font-normal': option.value !== internalValue.value,
            }, "block truncate"])
            }, _toDisplayString(option.text), 3)
          ], 8, _hoisted_4))
        }), 128))
      ], 512), [
        [_vShow, isOpen.value]
      ])
    ]),
    (__props.showError)
      ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(__props.ErrorText), 1))
      : _createCommentVNode("", true)
  ], 512))
}
}

})