import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "fixed flex h-[100vh] w-[100vw] items-center justify-center p-4 z-50 top-0 left-0 backdrop-blur-[3px] bg-[rgba(0,0,0,0.4)]" }
const _hoisted_2 = { class: "flex flex-col gap-4" }
const _hoisted_3 = { class: "flex flex-col gap-4" }
const _hoisted_4 = { class: "flex flex-col gap-2" }
const _hoisted_5 = { class: "flex items-center" }
const _hoisted_6 = {
  key: 0,
  class: "ml-2 transition"
}

import { ref } from 'vue';
import { fundService } from '@/services';
import InputCustom from '@/components/InputCustom.vue';
import InputTextArea from '@/components/InputTextArea.vue';
import { IFundInfo, RoleType } from '@/interfaces/dto';
import InputSelect from '@/components/InputSelect.vue';
import { ICustomSelectOption } from '@/interfaces/ICustomSelectOption';
import { userService } from '@/services/userService';
import SpinnerLoading from '@/components/SpinnerLoading.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'AddFund',
  props: {
    closeAdd: { type: Function }
  },
  emits: ['fundAdded'],
  setup(__props: any, { emit: __emit }) {

/* import */
const model = ref<IFundInfo>({ name: '' });
const userSelect = ref<{ current: ICustomSelectOption<string>; data: ICustomSelectOption<string>[] }>({
  current: { value: '', text: '' },
  data: [],
});
const showErrorFundName = ref(false);
const showErrorGeneral = ref(false);
const errorText = ref('Hubo un error creando el fondo');
const isLoading = ref(false);


const emit = __emit;

const handleAdd = async () => {
  showErrorGeneral.value = false;
  isLoading.value = true;
  if (validationFundCreate()) {
    const fund = await fundService.create(model.value).catch((error) => {
      showErrorGeneral.value = true;
      console.error('Create failed:', error);
      throw error;
    });

    const userId = userSelect.value.current.value;
    if (userId !== '') {
      await fundService.attachUser(fund.id, userId).catch((error) => {
        showErrorGeneral.value = true;
        console.error('Attach User failed:', error);
        throw error;
      });
    }

    isLoading.value = false;
    emit('fundAdded');
  }
};

async function fetchUsers(search: string[]) {
  try {
    const res = await userService.list(search, 0, 10, true);
    userSelect.value.data = res.data
      .filter((user) => user.role === RoleType.Assessor)
      .map((user) => ({ text: user.username, value: user.id } as ICustomSelectOption<string>));
  } catch (error) {
    showErrorGeneral.value = true;
    console.error('user fetch failed:', error);
  }
}

function validationFundCreate() {
  showErrorFundName.value = model.value.name === '';
  return !showErrorFundName.value;
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("form", {
      onSubmit: _withModifiers(handleAdd, ["prevent"]),
      class: "flex w-[500px] flex-col gap-6 bg-white p-12 shadow-custom-shadow rounded-lg",
      autocomplete: "off"
    }, [
      _cache[8] || (_cache[8] = _createElementVNode("div", { class: "flex flex-col gap-2" }, [
        _createElementVNode("h4", { class: "text-primary" }, "Agregar Fondo")
      ], -1)),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(InputCustom, {
            modelValue: model.value.name,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((model.value.name) = $event)),
            "show-error": showErrorFundName.value,
            placeholder: "---",
            title: "Nombre",
            type: "text"
          }, null, 8, ["modelValue", "show-error"]),
          _createVNode(InputCustom, {
            modelValue: model.value.address,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((model.value.address) = $event)),
            placeholder: "---",
            title: "Dirección *",
            type: "text"
          }, null, 8, ["modelValue"]),
          _createVNode(InputSelect, {
            "model-value": userSelect.value.current,
            options: userSelect.value.data,
            placeholder: "---",
            "show-search": "",
            title: "Asignar asesor *",
            onEmitValues: _cache[2] || (_cache[2] = (value) => fetchUsers(value)),
            "onUpdate:modelValue": _cache[3] || (_cache[3] = (value) => (userSelect.value.current = value))
          }, null, 8, ["model-value", "options"]),
          _createVNode(InputTextArea, {
            modelValue: model.value.details,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((model.value.details) = $event)),
            placeholder: "---",
            title: "Detalles *"
          }, null, 8, ["modelValue"])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("span", {
            class: _normalizeClass(["text-sm text-red-600 transition-all", {
              'scale-100': showErrorGeneral.value,
              'scale-0': !showErrorGeneral.value,
            }])
          }, _toDisplayString(errorText.value), 3),
          _cache[7] || (_cache[7] = _createElementVNode("label", null, "Los campos con ( * ) son opcionales.", -1)),
          _createElementVNode("div", _hoisted_5, [
            _cache[6] || (_cache[6] = _createElementVNode("button", {
              class: "w-full bg-primary text-white",
              type: "submit"
            }, "AGREGAR", -1)),
            (isLoading.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createVNode(SpinnerLoading)
                ]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("button", {
            onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.closeAdd && _ctx.closeAdd(...args))),
            type: "button",
            class: "w-full bg-white text-primary border-primary border-solid border-[1px] text-nowrap"
          }, " CERRAR ")
        ])
      ])
    ], 32)
  ]))
}
}

})