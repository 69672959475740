import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, renderSlot as _renderSlot, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col gap-2" }
const _hoisted_2 = { class: "relative overflow-x-auto shadow-custom-shadow bg-white rounded-lg" }
const _hoisted_3 = { class: "w-full text-sm text-left min-h-[540px]" }
const _hoisted_4 = { class: "text-[14px] text-[#344767] text-nowrap" }
const _hoisted_5 = { class: "h-[50px]" }
const _hoisted_6 = {
  key: 0,
  class: "h-[50px]"
}
const _hoisted_7 = ["colspan"]
const _hoisted_8 = { class: "flex justify-center p-4" }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = ["onClick"]
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { key: 1 }
const _hoisted_13 = ["colspan"]
const _hoisted_14 = { class: "w-full justify-end flex gap-3" }
const _hoisted_15 = { class: "shadow-custom-shadow bg-white w-min flex flex-row items-center rounded-lg" }
const _hoisted_16 = { class: "shadow-custom-shadow bg-white w-min flex flex-row items-center rounded-lg" }
const _hoisted_17 = { class: "flex flex-nowrap gap-2" }
const _hoisted_18 = { class: "text-nowrap" }
const _hoisted_19 = { class: "text-nowrap font-bold" }
const _hoisted_20 = { class: "shadow-custom-shadow bg-white w-min flex flex-row items-center rounded-lg" }


import ArrowDownIcon from './icons/ArrowDownIcon.vue';
import SpinnerLoading from '@/components/SpinnerLoading.vue'; // Asegúrate de importar el componente SpinnerLoanding


export default /*@__PURE__*/_defineComponent({
  __name: 'TableCustom',
  props: {
    headers: {},
    data: {},
    pageCurrent: {},
    totalPage: {},
    enabledNext: { type: Boolean },
    enabledBack: { type: Boolean },
    nextPage: { type: Function },
    backPage: { type: Function },
    startPage: { type: Function },
    endPage: { type: Function },
    keys: {},
    noDataMessage: {},
    loading: { type: Boolean },
    hiddenMobile: {}
  },
  emits: ['returnId', 'returnItem'],
  setup(__props: any, { emit: __emit }) {

const emit = __emit;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const watchClickInObject = (item: any) => {
  emit('returnId', item.id);
  emit('returnItem', item);
};

const props = __props;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const reduceObject = (obj: any, path: string[]) => {
  return path.reduce((prev, key) => {
    return prev?.[key];
  }, obj);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("table", _hoisted_3, [
        _createElementVNode("thead", _hoisted_4, [
          _createElementVNode("tr", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.headers, (header, i) => {
              return (_openBlock(), _createElementBlock("th", {
                key: i,
                scope: "col",
                class: _normalizeClass(["px-6 py-3", { hiddenCol: props.hiddenMobile.includes(i) }])
              }, _toDisplayString(header), 3))
            }), 128)),
            _renderSlot(_ctx.$slots, "th")
          ])
        ]),
        _createElementVNode("tbody", null, [
          (_ctx.data.length < 1)
            ? (_openBlock(), _createElementBlock("tr", _hoisted_6, [
                _createElementVNode("td", {
                  colspan: _ctx.headers.length,
                  class: "align-center"
                }, [
                  _createElementVNode("div", _hoisted_8, [
                    (_ctx.loading)
                      ? (_openBlock(), _createBlock(SpinnerLoading, { key: 0 }))
                      : (_openBlock(), _createElementBlock("p", _hoisted_9, _toDisplayString(props.noDataMessage || 'No data available'), 1))
                  ])
                ], 8, _hoisted_7)
              ]))
            : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.data, (item, i) => {
                return (_openBlock(), _createElementBlock("tr", {
                  class: "border-b cursor-pointer relative h-[50px]",
                  key: i,
                  onClick: ($event: any) => (watchClickInObject(item))
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.keys, (key, j) => {
                    return (_openBlock(), _createElementBlock("td", {
                      key: j,
                      class: _normalizeClass(["px-6 py-4 max-h-[50px]", { hiddenCol: props.hiddenMobile.includes(j) }])
                    }, [
                      (typeof key === 'object')
                        ? (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(reduceObject(item, key)), 1))
                        : (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(item[key]), 1))
                    ], 2))
                  }), 128)),
                  _renderSlot(_ctx.$slots, "td", { item: item })
                ], 8, _hoisted_10))
              }), 128)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(10 - _ctx.data.length, (i) => {
            return _withDirectives((_openBlock(), _createElementBlock("tr", {
              key: 'empty-' + i,
              class: "border-b h-[50px]"
            }, [
              _createElementVNode("td", {
                colspan: _ctx.headers.length,
                class: "px-6 py-4"
              }, null, 8, _hoisted_13)
            ])), [
              [_vShow, !props.loading && _ctx.data.length > 0]
            ])
          }), 128))
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_14, [
      _createElementVNode("div", _hoisted_15, [
        _createElementVNode("button", {
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.startPage && _ctx.startPage(...args))),
          class: _normalizeClass(["text-[#344767] flex px-4", { 'opacity-60 cursor-default pointer-events-none': !_ctx.enabledBack }])
        }, [
          _createVNode(ArrowDownIcon, { class: "rotate-90 w-4 h-4" }),
          _createVNode(ArrowDownIcon, { class: "rotate-90 w-4 h-4" })
        ], 2)
      ]),
      _createElementVNode("div", _hoisted_16, [
        _createElementVNode("button", {
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.backPage && _ctx.backPage(...args))),
          class: _normalizeClass(["text-[#344767]", { 'opacity-60 cursor-default pointer-events-none': !_ctx.enabledBack }])
        }, [
          _createVNode(ArrowDownIcon, { class: "rotate-90 w-4 h-4" })
        ], 2),
        _createElementVNode("div", _hoisted_17, [
          _createElementVNode("span", _hoisted_18, _toDisplayString(_ctx.pageCurrent), 1),
          _cache[4] || (_cache[4] = _createElementVNode("span", { class: "text-nowrap" }, _toDisplayString(' / '), -1)),
          _createElementVNode("span", _hoisted_19, _toDisplayString(_ctx.totalPage || 0), 1)
        ]),
        _createElementVNode("button", {
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.nextPage && _ctx.nextPage(...args))),
          class: _normalizeClass(["text-[#344767]", { 'opacity-60 cursor-default pointer-events-none': !_ctx.enabledNext }])
        }, [
          _createVNode(ArrowDownIcon, { class: "-rotate-90 w-4 h-4" })
        ], 2)
      ]),
      _createElementVNode("div", _hoisted_20, [
        _createElementVNode("button", {
          onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.endPage && _ctx.endPage(...args))),
          class: _normalizeClass(["text-[#344767] flex px-4", { 'opacity-60 cursor-default pointer-events-none': !_ctx.enabledNext }])
        }, [
          _createVNode(ArrowDownIcon, { class: "-rotate-90 w-4 h-4" }),
          _createVNode(ArrowDownIcon, { class: "-rotate-90 w-4 h-4" })
        ], 2)
      ])
    ])
  ]))
}
}

})