import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col gap-6" }
const _hoisted_2 = {
  id: "details",
  class: "flex flex-wrap gap-6"
}

import ListModal from '../default/ListModal.vue';
import { IFundDto, IFundFilter } from '@/interfaces/dto';
import { onMounted, ref } from 'vue';
import { currencyService, fundService } from '@/services';
import AddFund from './AddFund.vue';
import DetailsFund from './DetailsFund.vue';
import FiltersFund from './FiltersFund.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'FundView',
  setup(__props) {

const showAdd = ref(false);
const showFilter = ref(false);
const data = ref<IFundDto[]>([]);
const header = ref<string[]>([]);
const keys = ref<string[]>([]);
const loading = ref(false);
const pageCurrent = ref(1);
const totalPageCurrent = ref(0);
const enabledNext = ref(false);
const enabledBack = ref(false);
const filter = ref<IFundFilter>({});
const currencyName = ref<string[]>([]);
const fundSelectd = ref<IFundDto | null>(null);

const fetchData = async () => {
  loading.value = true;
  data.value = [];
  await fetchDataCurrency();
  const res = await fundService.list(filter.value, pageCurrent.value - 1);
  if (res === undefined) return;
  totalPageCurrent.value = Math.ceil(res.totalLenght / 10);
  data.value = res.data;
  enabledNext.value = pageCurrent.value < totalPageCurrent.value;
  enabledBack.value = pageCurrent.value > 1;
  loading.value = false;
  fundSelectd.value !== null && getFundfromId(fundSelectd.value as { id: string });
};
const fetchDataCurrency = async () => {
  currencyName.value = [];
  const res = await currencyService.list();
  if (res === undefined) return;
  currencyName.value = res.map((res) => res.currency);
  header.value = ['Fondo', 'Usuario', ...currencyName.value];
  keys.value = ['name', 'user', ...currencyName.value];
};
const showAddFunct = () => {
  showAdd.value = true;
};
const closeAddFunct = () => {
  showAdd.value = false;
};
const handleFundAdded = async () => {
  closeAddFunct();
  await fetchData();
};
const showFilterFunct = () => {
  showFilter.value = true;
};
const closeFilterFunct = () => {
  showFilter.value = false;
};
const handleFilter = async (filterValue: IFundFilter) => {
  closeFilterFunct();
  filter.value = filterValue;
  pageCurrent.value = 1;
  await fetchData();
};
const handleResetFilter = async (filterValue: IFundFilter) => {
  closeFilterFunct();
  filter.value = filterValue;
  pageCurrent.value = 1;
  await fetchData();
};
const formatFundDataIntoTableInput = (data: IFundDto) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const tableInput = { id: data.id, name: data.name, user: data.user?.username || ' --- ' } as any;
  const currencyDataIsNull = data.currencies === null;
  const fundCurrencies = data.currencies as { currency: string; amount: number }[];

  for (const currency of currencyName.value) {
    tableInput[currency] = 0;
    if (currencyDataIsNull) continue;
    let fundCurrency = fundCurrencies.find((fundCurrency) => fundCurrency.currency == currency);
    if (fundCurrency === undefined) continue;
    tableInput[currency] = fundCurrency.amount;
  }

  return tableInput;
};
const nextPage = () => {
  if (pageCurrent.value < totalPageCurrent.value) {
    pageCurrent.value++;
    fetchData();
  }
};
const backPage = () => {
  if (pageCurrent.value > 1) {
    pageCurrent.value--;
    fetchData();
  }
};
const startPage = () => {
  pageCurrent.value = 1;
  fetchData();
};

const endPage = () => {
  pageCurrent.value = totalPageCurrent.value;
  fetchData();
};
const getFundfromId = (value: { id: string }) =>
  (fundSelectd.value = data.value.find((e: IFundDto) => e.id === value.id) || null);

onMounted(async () => {
  await fetchData();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(ListModal, {
        name: "Fondos",
        "add-enabled": true,
        "show-add": showAddFunct,
        "hidden-mobile": [2, ...Array.from({ length: keys.value.length }, (_, i) => i + 3)],
        "is-loading": loading.value,
        header: header.value,
        keys: keys.value,
        data: data.value.map(formatFundDataIntoTableInput),
        "next-page": nextPage,
        "enabled-next": enabledNext.value,
        "enabled-back": enabledBack.value,
        "back-page": backPage,
        "page-current": pageCurrent.value,
        "show-filter": showFilterFunct,
        "filter-enabled": data.value.length > 1,
        "total-page": totalPageCurrent.value,
        "start-page": startPage,
        "end-page": endPage,
        "button-label": "AGREGAR FONDO",
        onReturnItem: getFundfromId
      }, null, 8, ["hidden-mobile", "is-loading", "header", "keys", "data", "enabled-next", "enabled-back", "page-current", "filter-enabled", "total-page"]),
      _createElementVNode("section", _hoisted_2, [
        (fundSelectd.value !== null)
          ? (_openBlock(), _createBlock(DetailsFund, {
              key: 0,
              fund: fundSelectd.value,
              onRefresh: _cache[0] || (_cache[0] = ($event: any) => (fetchData())),
              onFundDelete: _cache[1] || (_cache[1] = ($event: any) => (fetchData(), (fundSelectd.value = null)))
            }, null, 8, ["fund"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createVNode(FiltersFund, {
      "show-filter": showFilter.value,
      "close-filter": closeFilterFunct,
      onFilterValue: _cache[2] || (_cache[2] = 
      (filters) => {
        handleFilter(filters);
      }
    ),
      onRestartFilterValue: _cache[3] || (_cache[3] = (filters) => handleResetFilter(filters))
    }, null, 8, ["show-filter"]),
    (showAdd.value)
      ? (_openBlock(), _createBlock(AddFund, {
          key: 0,
          onFundAdded: handleFundAdded,
          "close-add": closeAddFunct
        }))
      : _createCommentVNode("", true)
  ], 64))
}
}

})