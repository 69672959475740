import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "fixed flex h-[100vh] w-[100vw] items-center justify-center p-4 z-50 top-0 left-0 backdrop-blur-[3px] bg-[rgba(0,0,0,0.4)]" }
const _hoisted_2 = { class: "flex flex-col gap-4" }
const _hoisted_3 = { class: "flex flex-col gap-2" }

import { onMounted, Ref, ref, watch } from 'vue';
import { currencyService, fundService } from '@/services';
import InputSelect from '@/components/InputSelect.vue';
import InputCustom from '@/components/InputCustom.vue';
import InputTextArea from '@/components/InputTextArea.vue';
import { IFundDto, ITransferInfoDto } from '@/interfaces/dto';

/* Validation const */
interface currency {
  currency: string;
  amount: number;
}

interface option {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
  text: string;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'TransferFund',
  props: {
    closeTransfer: { type: Function },
    id: {},
    currencies: {}
  },
  emits: ['fundTransfer'],
  setup(__props: any, { emit: __emit }) {

/* import */
const showErrorGeneral: Ref<boolean> = ref(false);
const errorText: Ref<string> = ref('Ocurrio un error');
const inputCurrency = ref(0);
const showErrorInputCurrency = ref(false);
const showErrorSelectCurrency = ref(false);
const textErrorInputCurrency = ref('El valor debe ser mayor que 0');
const details = ref('');

/* props and emits*/
const props = __props;
const emit = __emit;

/* Transfer */
const handleTransfer = async () => {
  showErrorGeneral.value = false;
  if (validation()) {
    try {
      const transfer: ITransferInfoDto = {
        source: props.id,
        currency: optionSelectCurrenci.value.value,
        details: details.value == '' ? null : details.value,
        amount: inputCurrency.value,
        destination: optionSelectFundName.value.value,
      };
      await fundService.transfer(transfer);
      restart();

      emit('fundTransfer');
    } catch (error) {
      showErrorGeneral.value = true;
      console.error('Transfer failed:', error);
    }
  }
};

const validation = () => {
  showErrorInputCurrency.value = false;
  showErrorSelectCurrency.value = false;
  showErrorSelectFund.value = false;

  const selectedCurrency = props.currencies?.find((curr) => curr.currency === optionSelectCurrenci.value.text);
  const maxAmount = selectedCurrency ? selectedCurrency.amount : 0;

  if (maxAmount > 0 && optionSelectFundName.value.value !== '') {
    if (inputCurrency.value < 1 || inputCurrency.value > maxAmount) {
      showErrorInputCurrency.value = true;
      textErrorInputCurrency.value =
        inputCurrency.value < 1 ? 'El valor debe ser mayor que 0' : 'El valor no puede sobrepasar ' + maxAmount;
      inputCurrency.value = 0;
    }
  } else {
    showErrorInputCurrency.value = true;
    textErrorInputCurrency.value = 'Se requiere una moneda y campo seleccionados';
  }

  if (optionSelectCurrenci.value.value === '') {
    showErrorSelectCurrency.value = true;
  }
  if (optionSelectFundName.value.value === '') {
    showErrorSelectFund.value = true;
  }

  return !(showErrorInputCurrency.value || showErrorSelectCurrency.value || showErrorSelectFund.value);
};

const restart = () => {
  inputCurrency.value = 0;
  showErrorInputCurrency.value = false;
  showErrorSelectCurrency.value = false;
  showErrorSelectFund.value = false;
  details.value = '';
  optionSelectCurrenci.value = { value: '', text: '' };
  optionSelectFundName.value = { value: '', text: '' };
  showErrorGeneral.value = false;
};

/* currencies */
const optionSelectCurrenci: Ref<option> = ref({ value: '', text: '' });
const optionsCurrencies: Ref<option[]> = ref([]);

const fetchCurrencies = async () => {
  try {
    const res = await currencyService.list();
    optionsCurrencies.value = res
      .map((currency) => ({
        value: currency.id,
        text: currency.currency,
      }))
      .filter((option) => option.value !== '')
      .filter((option) => option.text !== '')
      .filter((option) => props.currencies?.some((curr) => curr.currency === option.text));
  } catch (error) {
    showErrorGeneral.value = true;
    console.error(error);
  }
};

/* fundsNames */
const fundNamesRef: Ref<string[]> = ref([]);
const optionSelectFundName: Ref<option> = ref({ value: '', text: '' });
const optionsFundsNames: Ref<option[]> = ref([]);
const showErrorSelectFund: Ref<boolean> = ref(false);

const fetchFundsNames = async () => {
  console.log(fundNamesRef.value);
  try {
    const res = await fundService.list({ fundNames: fundNamesRef.value }, 0, 10);
    optionsFundsNames.value = res.data
      .map((fund: IFundDto) => ({
        value: fund.id,
        text: fund.name,
      }))
      .filter((option) => option.value !== '')
      .filter((option) => option.value !== props.id);
  } catch (error) {
    showErrorGeneral.value = true;
    console.error(error);
  }
};

onMounted(() => {
  fetchCurrencies();
  fetchFundsNames();
  restart();
});

watch(
  () => props.id,
  (newId) => {
    if (newId) {
      restart();
    }
  }
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("form", {
      onSubmit: _withModifiers(handleTransfer, ["prevent"]),
      class: "flex w-[360px] flex-col gap-6 bg-white p-12 shadow-custom-shadow rounded-lg",
      autocomplete: "off"
    }, [
      _cache[7] || (_cache[7] = _createElementVNode("div", { class: "flex flex-col gap-2" }, [
        _createElementVNode("h4", { class: "text-primary" }, "Transferir")
      ], -1)),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(InputSelect, {
          "model-value": optionSelectCurrenci.value,
          options: optionsCurrencies.value,
          title: "Moneda",
          "show-error": showErrorSelectCurrency.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = (value) => (optionSelectCurrenci.value = value))
        }, null, 8, ["model-value", "options", "show-error"]),
        _createVNode(InputSelect, {
          "model-value": optionSelectFundName.value,
          options: optionsFundsNames.value,
          "show-search": true,
          title: "Fondo",
          "show-error": showErrorSelectFund.value,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = (value) => (optionSelectFundName.value = value)),
          onEmitValues: _cache[2] || (_cache[2] = async (values) => ((fundNamesRef.value = values), await fetchFundsNames()))
        }, null, 8, ["model-value", "options", "show-error"]),
        _createVNode(InputCustom, {
          modelValue: inputCurrency.value,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((inputCurrency).value = $event)),
          title: "Cantidad",
          type: "number",
          "show-error": showErrorInputCurrency.value,
          "-error-text": textErrorInputCurrency.value
        }, null, 8, ["modelValue", "show-error", "-error-text"]),
        _createVNode(InputTextArea, {
          modelValue: details.value,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((details).value = $event)),
          title: "Detalles"
        }, null, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", {
          class: _normalizeClass(["text-sm text-red-600 transition-all", {
            'scale-100': showErrorGeneral.value,
            'scale-0': !showErrorGeneral.value,
          }])
        }, _toDisplayString(errorText.value), 3),
        _cache[6] || (_cache[6] = _createElementVNode("button", {
          class: "w-full bg-primary text-white",
          type: "submit"
        }, "TRANSFERIR", -1)),
        _createElementVNode("button", {
          onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.closeTransfer())),
          type: "button",
          class: "w-full bg-white text-primary border-primary border-solid border-[1px] text-nowrap"
        }, " CERRAR ")
      ])
    ], 32)
  ]))
}
}

})